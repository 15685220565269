import Router from './Router'

class App {
  constructor(){
    this.name = "Peta's Portfolio"
    this.version = "1.0.2"
    this.apiBase = 'https://ps.hashtag-webapp.com'
    this.rootEl = document.getElementById("root")
  }
  // Work Version
  // http://localhost:3000
  // https://my.hashtag-metoo.com  OLD
  // https://ps.hashtag-webapp.com' NEW

  init() { 
     Router.init()  
  } 

  resizeListener(){
    document.body.style.height = window.innerHeight + 'px';  
  }
  
}

export default new App()