import App from './App.js'
import splash from './views/partials/splash'
import {html, render } from 'lit-html'
import './components/main-app-header'
import './components/c-error'
import './components/c-success'
import './components/c-waiting'
import './scss/master.scss'

render(splash, App.rootEl)

document.addEventListener('DOMContentLoaded', () => {
  App.init()
})

window.addEventListener("resize", () => {
  App.resizeListener()
})
