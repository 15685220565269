import App from '../../App'
import {render, html} from 'lit/html.js'
import {gotoRoute, anchorRoute } from '../../Router'


class HomeView {
  init(){    
    console.log('Home View.init')
    document.title = 'Peta'    
    this.render() 
  }

  nextSection(e){
    const thisSection = e.target.closest('section').getAttribute("id")
    const lastChar = thisSection.slice(-1)
    const num = Number(lastChar) + 1
    const nextSectionId = 'section-' + num
    const nextSection = document.getElementById(nextSectionId)
    nextSection.scrollIntoView( { behavior: 'smooth'})
  }

  backtoTop(){
    const myDiv = document.getElementById('contentpanelid');
    myDiv.scrollTo( {top: 0, behavior: 'smooth'})
  }

  render(){
    const template = html`
     
      <div id="contentpanelid" class="content-panel">  
      <main-app-header pagename="${document.title}"></main-app-header>
        <section id="section-1" class="v-center">
          <div class="h-center"> 
            <div id="logo-holder">
              <svg id="peta-cover" alt="Peta logo main" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  height="100%" viewBox="0 0 240.333 143.333" enable-background="new 0 0 240.333 143.333"
                  xml:space="preserve">
                <g id="Layer_1">
                </g>
                <g id="side_details">
                  <g>
                    <g>
                      <g>
                        <g>
                          <path fill="#0BA998" d="M57.25,64.628c0.447,0.463,1.254,0.965,2.234,1.433c0.969,0.461,2.063,0.869,3.199,1.21
                            c2.279,0.685,4.729,1.12,7.191,1.292c4.921,0.342,9.96-0.361,14.342-2.5c4.399-2.104,8.033-5.791,10.353-10.477
                            c2.345-4.672,3.458-10.125,3.804-15.635c0.043-0.689,0.072-1.383,0.099-2.076c-0.013-0.662-0.056-1.406-0.118-2.062
                            c-0.123-1.349-0.316-2.718-0.617-4.051c-0.591-2.674-1.531-5.272-2.841-7.685c-2.603-4.837-6.685-8.843-11.495-11.582
                            C78.594,9.75,73.085,8.184,67.514,8.023c-2.782-0.081-5.587,0.222-8.277,0.967c-2.682,0.76-5.258,2.004-7.391,3.839
                            c2.084-1.888,4.644-3.209,7.328-4.043c2.693-0.821,5.525-1.202,8.347-1.191c5.646,0.021,11.292,1.462,16.298,4.151
                            c5,2.684,9.347,6.716,12.201,11.707c1.43,2.484,2.488,5.184,3.177,7.969c0.35,1.396,0.588,2.805,0.755,4.242
                            c0.098,0.738,0.121,1.397,0.195,2.135c0.085,0.705,0.16,1.414,0.223,2.129c0.486,5.676,0.251,11.592-1.61,17.355
                            c-0.927,2.871-2.296,5.674-4.141,8.215c-1.835,2.544-4.153,4.796-6.776,6.608c-5.273,3.648-11.557,5.507-17.816,6.026
                            c-3.148,0.258-6.319,0.194-9.532-0.252c-1.61-0.223-3.233-0.548-4.901-1.035c-1.649-0.492-3.436-1.139-5.277-2.305
                            L57.25,64.628z"/>
                        </g>
                        <g>
                          <path fill="#43C0B9" d="M82.223,54.2l-6.506-12.285l9.092-6.38c0,0-3.791-2.949-5.045-3.774
                            c-2.512-1.655-5.177-3.098-7.93-4.323c-2.729-1.211-5.59-2.166-8.506-2.857c-2.9-0.685-5.943-1.15-8.801-1.226l0.098-4.839
                            c3.422,0.127,6.615,0.668,9.822,1.447c3.184,0.79,6.3,1.856,9.301,3.222c2.972,1.357,5.832,2.94,8.553,4.77
                            c1.357,0.914,2.678,1.887,3.954,2.933c1.294,1.072,5.421,5.339,5.421,5.339l-10.74,7.303L82.223,54.2z"/>
                        </g>
                        <g>
                          <path fill="#0BA998" d="M54.553,23.421c1.186,5.689,2.074,9.215,2.662,15.059c0.209,2.922,0.268,5.83,0.336,8.74
                            c0.039,2.145,0.063,6.428,0.063,6.428l11.896-6.65l5.028,11.544l7.569-4.544l2.23,4.293l-9.793,4.324l-1.754,0.772l-0.65-1.647
                            l-4.15-10.512l-13.068,6.885c0,0,0.483-13.794,0.424-19.554C55.389,32.811,55.125,29.197,54.553,23.421z"/>
                        </g>
                        <g>
                          <path fill="#43C0B9" d="M42.432,10.238c2.723,6.317,4.16,13.195,5.031,20.084c0.85,6.905,1.094,13.891,0.961,20.863
                            c-0.308,13.959-2.035,27.891-5.065,41.695l-11.593-3.455c4.732-12.447,8.366-25.495,10.637-38.798
                            c1.113-6.653,1.873-13.382,2.04-20.153C44.583,23.718,44.194,16.88,42.432,10.238z"/>
                        </g>
                      </g>
                      <path fill="#43C0B9" d="M186.051,53.659c-0.621-0.521-1.504-0.781-2.65-0.781c-1.099,0-2.102,0.087-3.008,0.261
                        c-0.239-0.954-0.537-1.616-0.895-1.985c-0.358-0.368-1.063-0.554-2.113-0.554c-3.294,0-6.409,0.943-9.346,2.832
                        c-2.936,1.887-5.288,4.383-7.054,7.485c-1.668,2.929-2.538,6.024-2.631,9.283c-1.019,0.938-1.96,1.727-2.812,2.336
                        c-1.575,1.129-3.391,1.692-5.442,1.692c-2.531,0-4.381-0.912-5.551-2.734s-1.754-4.989-1.754-9.503
                        c0-1.215,0.047-2.907,0.143-5.077h8.737c5.395,0,8.093-1.498,8.093-4.492c0-0.823-0.168-1.388-0.502-1.691
                        s-0.884-0.456-1.646-0.456h-8.021c6.206-4.989,9.311-10.849,9.311-17.575c0-2.43-0.573-4.448-1.719-6.054
                        c-1.146-1.605-2.722-2.409-4.727-2.409c-2.722,0-5.264,1.172-7.627,3.515s-4.381,5.49-6.052,9.439
                        c-1.672,3.949-2.912,8.311-3.724,13.083h-4.082c-2.148,0-3.223,1.216-3.223,3.646c0,1.041,0.251,1.801,0.752,2.277
                        c0.501,0.479,1.468,0.717,2.9,0.717h2.793c-0.144,1.953-0.215,3.884-0.215,5.793c0,1.407,0.071,2.723,0.195,3.97
                        c-0.428,0.14-0.829,0.391-1.198,0.782c-2.004,2.213-4.381,4.003-7.125,5.369c-2.746,1.367-5.191,2.051-7.34,2.051
                        c-3.344,0-5.611-0.998-6.805-2.994c5.634-1.258,9.633-2.961,11.996-5.109s3.545-4.719,3.545-7.713
                        c0-2.561-0.836-4.59-2.506-6.087c-1.672-1.497-3.893-2.245-6.66-2.245c-3.104,0-5.885,0.76-8.344,2.277
                        c-2.459,1.52-4.381,3.57-5.766,6.152s-2.076,5.369-2.076,8.363c0,4.297,1.385,7.682,4.154,10.154
                        c2.768,2.475,6.66,3.711,11.672,3.711c3.676,0,7.127-0.824,10.35-2.474s5.717-3.45,7.482-5.403
                        c0.06-0.063,0.101-0.142,0.157-0.208c2.194,5.377,6.477,8.084,12.877,8.084c3.15,0,5.943-0.738,8.379-2.213
                        c1.013-0.613,1.984-1.315,2.931-2.072c0.287,0.479,0.605,0.934,0.972,1.355c1.694,1.953,3.903,2.93,6.624,2.93
                        c3.628,0,6.183-1.865,7.663-5.598l-0.071,1.041c0,1.605,0.394,2.767,1.182,3.482c0.787,0.716,1.992,1.074,3.616,1.074
                        c1.671,0,2.876-0.358,3.616-1.074s1.11-1.898,1.11-3.547c0-2.995,0.144-5.696,0.43-8.104c0.286-2.409,0.691-4.937,1.218-7.583
                        c0.477-2.475,0.716-4.209,0.716-5.208C186.981,54.918,186.67,54.18,186.051,53.659z M145.624,39.697
                        c0.788-2.886,1.623-5.143,2.507-6.77c0.883-1.627,1.683-2.441,2.399-2.441c1.05,0,1.575,1.28,1.575,3.84
                        c0,2.257-0.705,4.763-2.112,7.518c-1.409,2.757-3.521,5.24-6.338,7.453C144.18,45.783,144.837,42.584,145.624,39.697z
                        M110.57,66.678c0-2.646,0.607-4.903,1.826-6.77c1.217-1.865,2.709-2.799,4.475-2.799c0.859,0,1.541,0.25,2.041,0.748
                        c0.502,0.5,0.752,1.162,0.752,1.985c0,1.606-0.812,3.017-2.434,4.231c-1.625,1.215-3.844,2.127-6.66,2.733V66.678z
                        M176.884,63.228c-0.859,4.34-1.839,7.355-2.937,9.048c-1.099,1.692-2.316,2.539-3.652,2.539c-0.907,0-1.636-0.336-2.184-1.01
                        c-0.55-0.672-0.824-1.659-0.824-2.961c0-2.43,0.502-4.729,1.504-6.9c1.003-2.169,2.339-3.916,4.011-5.24
                        c1.671-1.322,3.484-2.006,5.442-2.05L176.884,63.228z"/>
                    </g>
                    <g>
                      <path fill="#B2B3B3" d="M4.163,113.371c0.95-0.094,1.88-0.164,2.79-0.211c0.909-0.046,1.949-0.07,3.123-0.07
                        c1.818,0,3.385,0.263,4.699,0.786c1.313,0.524,2.404,1.245,3.273,2.162c0.869,0.918,1.512,1.984,1.926,3.201
                        s0.621,2.509,0.621,3.874c0,1.367-0.211,2.663-0.637,3.889c-0.424,1.227-1.076,2.299-1.955,3.215
                        c-0.879,0.918-2.001,1.643-3.365,2.177c-1.364,0.533-2.997,0.8-4.896,0.8c-1.152,0-2.158-0.023-3.018-0.07
                        s-1.713-0.117-2.562-0.211V113.371z M9.984,115.393c-0.586,0-1.121,0.014-1.606,0.042s-0.931,0.061-1.335,0.099v15.189
                        c0.426,0.056,0.869,0.094,1.335,0.112c0.464,0.019,0.95,0.028,1.455,0.028c1.354,0,2.526-0.192,3.517-0.576
                        c0.99-0.383,1.805-0.917,2.441-1.601c0.637-0.683,1.106-1.497,1.41-2.442c0.303-0.944,0.454-1.988,0.454-3.131
                        c0-1.011-0.151-1.979-0.454-2.905c-0.304-0.927-0.77-1.75-1.395-2.471c-0.627-0.721-1.426-1.292-2.396-1.713
                        C12.44,115.604,11.299,115.393,9.984,115.393z"/>
                      <path fill="#B2B3B3" d="M25.902,115.87c-0.525,0-0.976-0.173-1.35-0.52s-0.561-0.763-0.561-1.25c0-0.542,0.172-0.973,0.516-1.291
                        s0.809-0.478,1.395-0.478c0.525,0,0.975,0.174,1.35,0.52c0.373,0.347,0.561,0.763,0.561,1.249c0,0.544-0.172,0.974-0.516,1.292
                        S26.488,115.87,25.902,115.87z M27.267,132.969h-2.729v-14.6h2.729V132.969z"/>
                      <path fill="#B2B3B3" d="M38.363,118.032c0.91,0,1.723,0.14,2.44,0.421c0.718,0.28,1.339,0.627,1.864,1.039v-1.123h2.486v14.263
                        c0,1.217-0.172,2.255-0.516,3.116s-0.833,1.567-1.47,2.12c-0.637,0.552-1.399,0.959-2.289,1.222
                        c-0.89,0.262-1.88,0.393-2.972,0.393c-0.95,0-1.859-0.103-2.729-0.309s-1.647-0.515-2.334-0.927l1-2.189
                        c0.484,0.354,1.051,0.649,1.697,0.884s1.436,0.352,2.365,0.352c1.436,0,2.563-0.347,3.381-1.039s1.229-1.816,1.229-3.369v-1.797
                        c-0.405,0.449-0.986,0.875-1.744,1.277s-1.662,0.604-2.713,0.604c-0.891,0-1.76-0.164-2.607-0.491
                        c-0.85-0.327-1.598-0.805-2.244-1.432s-1.168-1.395-1.562-2.303c-0.394-0.907-0.591-1.951-0.591-3.131
                        c0-1.085,0.191-2.092,0.576-3.018c0.383-0.927,0.904-1.728,1.561-2.401c0.657-0.674,1.43-1.202,2.32-1.586
                        C36.402,118.224,37.352,118.032,38.363,118.032z M38.666,130.723c0.809,0,1.536-0.169,2.184-0.506
                        c0.646-0.337,1.172-0.729,1.576-1.179v-7.637c-0.465-0.3-1-0.571-1.607-0.814c-0.605-0.243-1.334-0.365-2.182-0.365
                        c-0.627,0-1.229,0.117-1.805,0.351c-0.576,0.234-1.087,0.576-1.531,1.025c-0.445,0.449-0.799,0.997-1.061,1.643
                        c-0.264,0.646-0.395,1.39-0.395,2.232c0,0.805,0.131,1.529,0.395,2.176c0.262,0.646,0.615,1.198,1.061,1.656
                        c0.444,0.459,0.955,0.81,1.531,1.053S38.02,130.723,38.666,130.723z"/>
                      <path fill="#B2B3B3" d="M51.219,115.87c-0.525,0-0.976-0.173-1.35-0.52s-0.561-0.763-0.561-1.25c0-0.542,0.172-0.973,0.516-1.291
                        s0.809-0.478,1.395-0.478c0.525,0,0.975,0.174,1.35,0.52c0.373,0.347,0.561,0.763,0.561,1.249c0,0.544-0.172,0.974-0.516,1.292
                        S51.805,115.87,51.219,115.87z M52.583,132.969h-2.729v-14.6h2.729V132.969z"/>
                      <path fill="#B2B3B3" d="M58.677,114.298h2.093v4.604h4.365v2.189H60.77v7.048c0,0.543,0.064,1.001,0.196,1.376
                        c0.132,0.374,0.308,0.674,0.53,0.898s0.49,0.389,0.805,0.491c0.313,0.103,0.641,0.154,0.984,0.154
                        c0.506,0,0.98-0.074,1.426-0.225c0.443-0.149,0.868-0.327,1.273-0.533l0.758,2.021c-0.404,0.225-0.951,0.444-1.638,0.659
                        s-1.405,0.323-2.152,0.323c-1.517,0-2.714-0.458-3.593-1.376c-0.879-0.917-1.319-2.246-1.319-3.986v-6.852h-2.729v-2.189h2.79
                        L58.677,114.298z"/>
                      <path fill="#B2B3B3" d="M67.803,125.753c0-1.085,0.183-2.101,0.547-3.046c0.363-0.945,0.873-1.765,1.531-2.457
                        c0.656-0.692,1.444-1.235,2.364-1.629c0.919-0.393,1.925-0.589,3.017-0.589c0.91,0,1.732,0.145,2.471,0.435
                        c0.738,0.291,1.38,0.65,1.926,1.081v-1.179h2.486v11.062c0,0.712,0.187,1.175,0.561,1.391c0.374,0.215,0.783,0.322,1.229,0.322
                        l-0.576,1.966c-1.941,0-3.113-0.73-3.518-2.19c-0.242,0.3-0.521,0.595-0.834,0.885c-0.313,0.29-0.672,0.547-1.076,0.771
                        s-0.859,0.402-1.364,0.534c-0.505,0.131-1.062,0.196-1.667,0.196c-0.991,0-1.916-0.178-2.774-0.533
                        c-0.859-0.355-1.606-0.861-2.243-1.517s-1.143-1.445-1.517-2.372S67.803,126.914,67.803,125.753z M75.504,131.06
                        c0.769,0,1.496-0.169,2.184-0.506s1.263-0.776,1.729-1.319v-7.833c-0.546-0.355-1.148-0.642-1.805-0.856s-1.35-0.323-2.076-0.323
                        c-0.729,0-1.395,0.131-2.002,0.394c-0.605,0.262-1.127,0.631-1.561,1.108c-0.436,0.478-0.773,1.053-1.016,1.727
                        c-0.243,0.674-0.364,1.414-0.364,2.219c0,0.786,0.121,1.512,0.364,2.176c0.242,0.665,0.586,1.235,1.03,1.713
                        s0.966,0.847,1.562,1.108C74.145,130.929,74.797,131.06,75.504,131.06z"/>
                      <path fill="#B2B3B3" d="M90.148,128.14c0,1.011,0.227,1.76,0.682,2.246c0.455,0.487,1.086,0.729,1.895,0.729
                        c0.385,0,0.759-0.042,1.123-0.126c0.363-0.084,0.687-0.183,0.97-0.295l0.667,2.05c-0.363,0.15-0.814,0.281-1.35,0.394
                        s-1.127,0.168-1.773,0.168c-0.707,0-1.359-0.094-1.955-0.28c-0.597-0.188-1.117-0.478-1.563-0.871
                        c-0.444-0.393-0.793-0.898-1.045-1.516c-0.254-0.618-0.379-1.366-0.379-2.246v-16.201h2.729V128.14z"/>
                      <path fill="#B2B3B3" d="M107.096,129.066c0.729,0.562,1.547,1.021,2.457,1.375c0.909,0.356,1.971,0.534,3.184,0.534
                        c1.455,0,2.607-0.281,3.456-0.843s1.272-1.319,1.272-2.274c0-0.393-0.061-0.758-0.182-1.095s-0.359-0.66-0.712-0.969
                        c-0.354-0.309-0.849-0.617-1.485-0.927c-0.637-0.309-1.461-0.641-2.472-0.996c-1.091-0.394-2.017-0.781-2.774-1.165
                        s-1.369-0.805-1.834-1.264s-0.799-0.964-1-1.517c-0.203-0.552-0.304-1.184-0.304-1.895c0-0.692,0.156-1.349,0.47-1.966
                        c0.313-0.618,0.778-1.155,1.395-1.614c0.617-0.459,1.375-0.819,2.274-1.081s1.925-0.394,3.077-0.394
                        c1.254,0,2.375,0.117,3.365,0.352s1.971,0.566,2.941,0.996l-1.061,2.274c-0.688-0.374-1.492-0.688-2.411-0.94
                        c-0.92-0.253-1.885-0.379-2.896-0.379c-1.334,0-2.385,0.238-3.152,0.716c-0.77,0.478-1.152,1.109-1.152,1.896
                        c0,0.374,0.055,0.706,0.166,0.996c0.111,0.291,0.334,0.571,0.668,0.843c0.333,0.271,0.797,0.548,1.395,0.828
                        c0.596,0.281,1.389,0.59,2.379,0.927c1.173,0.412,2.163,0.833,2.972,1.264s1.456,0.889,1.94,1.375
                        c0.485,0.487,0.834,1.011,1.047,1.573c0.211,0.562,0.318,1.179,0.318,1.853c0,0.917-0.193,1.731-0.576,2.442
                        c-0.385,0.712-0.916,1.315-1.592,1.812c-0.678,0.496-1.491,0.87-2.441,1.123s-1.98,0.379-3.092,0.379
                        c-1.477,0-2.82-0.183-4.033-0.548c-1.213-0.364-2.233-0.847-3.063-1.445L107.096,129.066z"/>
                      <path fill="#B2B3B3" d="M123.166,125.669c0-1.18,0.201-2.241,0.605-3.187s0.955-1.746,1.652-2.401
                        c0.697-0.654,1.526-1.16,2.486-1.516s1.996-0.533,3.107-0.533c1.092,0,2.117,0.178,3.078,0.533
                        c0.959,0.355,1.793,0.861,2.501,1.516c0.708,0.655,1.263,1.451,1.668,2.387c0.403,0.937,0.606,1.984,0.606,3.145
                        c0,1.161-0.197,2.214-0.592,3.159s-0.945,1.755-1.652,2.429s-1.541,1.193-2.501,1.558c-0.96,0.365-1.997,0.548-3.108,0.548
                        c-1.132,0-2.178-0.188-3.138-0.562s-1.788-0.898-2.485-1.572s-1.244-1.479-1.638-2.415
                        C123.362,127.821,123.166,126.792,123.166,125.669z M136.082,125.669c0-0.73-0.127-1.423-0.379-2.078
                        c-0.254-0.655-0.607-1.226-1.063-1.713c-0.454-0.486-0.99-0.874-1.606-1.165c-0.616-0.29-1.288-0.435-2.017-0.435
                        c-1.597,0-2.84,0.486-3.729,1.46c-0.891,0.973-1.334,2.265-1.334,3.874c0,0.73,0.125,1.428,0.379,2.092
                        c0.252,0.665,0.605,1.245,1.061,1.741s0.99,0.889,1.607,1.179c0.616,0.291,1.289,0.436,2.016,0.436
                        c0.789,0,1.496-0.131,2.123-0.394c0.626-0.262,1.156-0.631,1.592-1.108c0.434-0.478,0.768-1.044,1-1.699
                        C135.965,127.204,136.082,126.474,136.082,125.669z"/>
                      <path fill="#B2B3B3" d="M145.086,128.14c0,1.011,0.227,1.76,0.682,2.246c0.455,0.487,1.086,0.729,1.895,0.729
                        c0.385,0,0.759-0.042,1.123-0.126c0.363-0.084,0.687-0.183,0.97-0.295l0.667,2.05c-0.363,0.15-0.814,0.281-1.35,0.394
                        s-1.127,0.168-1.773,0.168c-0.707,0-1.359-0.094-1.955-0.28c-0.597-0.188-1.117-0.478-1.563-0.871
                        c-0.444-0.393-0.793-0.898-1.045-1.516c-0.254-0.618-0.379-1.366-0.379-2.246v-16.201h2.729V128.14z"/>
                      <path fill="#B2B3B3" d="M166.643,133.109c-0.971,0-1.738-0.178-2.305-0.534c-0.565-0.354-0.96-0.87-1.182-1.544
                        c-0.708,0.674-1.521,1.222-2.441,1.643s-1.925,0.632-3.017,0.632c-0.687,0-1.349-0.099-1.985-0.295s-1.203-0.51-1.698-0.94
                        s-0.89-0.992-1.183-1.685s-0.439-1.535-0.439-2.527v-9.489h2.729v9.152c0,1.143,0.258,2.018,0.773,2.626s1.289,0.912,2.319,0.912
                        c0.849,0,1.657-0.154,2.426-0.463c0.768-0.31,1.455-0.726,2.062-1.25v-10.978h2.729v10.81c0,0.411,0.051,0.744,0.152,0.996
                        c0.101,0.253,0.231,0.449,0.394,0.59c0.161,0.141,0.354,0.238,0.576,0.295c0.222,0.056,0.444,0.084,0.667,0.084L166.643,133.109z
                        "/>
                      <path fill="#B2B3B3" d="M172.1,114.298h2.092v4.604h4.366v2.189h-4.366v7.048c0,0.543,0.066,1.001,0.197,1.376
                        c0.131,0.374,0.309,0.674,0.531,0.898c0.222,0.225,0.489,0.389,0.803,0.491s0.642,0.154,0.986,0.154
                        c0.504,0,0.979-0.074,1.425-0.225c0.444-0.149,0.868-0.327,1.273-0.533l0.758,2.021c-0.405,0.225-0.95,0.444-1.638,0.659
                        s-1.404,0.323-2.152,0.323c-1.516,0-2.714-0.458-3.594-1.376c-0.879-0.917-1.318-2.246-1.318-3.986v-6.852h-2.729v-2.189h2.789
                        L172.1,114.298z"/>
                      <path fill="#B2B3B3" d="M184.258,115.87c-0.526,0-0.976-0.173-1.35-0.52s-0.561-0.763-0.561-1.25
                        c0-0.542,0.172-0.973,0.516-1.291c0.343-0.318,0.808-0.478,1.395-0.478c0.525,0,0.975,0.174,1.349,0.52
                        c0.374,0.347,0.562,0.763,0.562,1.249c0,0.544-0.172,0.974-0.516,1.292S184.844,115.87,184.258,115.87z M185.622,132.969h-2.729
                        v-14.6h2.729V132.969z"/>
                      <path fill="#B2B3B3" d="M189.261,125.669c0-1.18,0.202-2.241,0.606-3.187s0.955-1.746,1.652-2.401
                        c0.697-0.654,1.525-1.16,2.486-1.516c0.96-0.355,1.995-0.533,3.107-0.533c1.092,0,2.117,0.178,3.077,0.533
                        s1.794,0.861,2.502,1.516c0.707,0.655,1.263,1.451,1.667,2.387c0.404,0.937,0.606,1.984,0.606,3.145
                        c0,1.161-0.197,2.214-0.591,3.159c-0.395,0.945-0.945,1.755-1.652,2.429c-0.708,0.674-1.542,1.193-2.502,1.558
                        c-0.96,0.365-1.996,0.548-3.107,0.548c-1.132,0-2.178-0.188-3.138-0.562c-0.961-0.374-1.789-0.898-2.486-1.572
                        s-1.243-1.479-1.638-2.415C189.458,127.821,189.261,126.792,189.261,125.669z M202.177,125.669c0-0.73-0.127-1.423-0.379-2.078
                        c-0.253-0.655-0.606-1.226-1.062-1.713c-0.455-0.486-0.99-0.874-1.606-1.165c-0.617-0.29-1.289-0.435-2.017-0.435
                        c-1.597,0-2.84,0.486-3.729,1.46c-0.89,0.973-1.334,2.265-1.334,3.874c0,0.73,0.126,1.428,0.379,2.092
                        c0.253,0.665,0.606,1.245,1.062,1.741s0.99,0.889,1.606,1.179c0.617,0.291,1.289,0.436,2.017,0.436
                        c0.788,0,1.496-0.131,2.122-0.394c0.627-0.262,1.157-0.631,1.592-1.108s0.769-1.044,1.001-1.699
                        C202.061,127.204,202.177,126.474,202.177,125.669z"/>
                      <path fill="#B2B3B3" d="M211.241,120.812c0.565-0.786,1.288-1.446,2.168-1.979c0.879-0.533,1.925-0.8,3.138-0.8
                        c1.778,0,3.107,0.496,3.987,1.487c0.879,0.992,1.318,2.303,1.318,3.931v9.519h-2.729v-9.237c0-1.067-0.263-1.914-0.788-2.541
                        c-0.526-0.627-1.273-0.94-2.244-0.94c-0.465,0-0.92,0.08-1.364,0.238c-0.444,0.159-0.874,0.379-1.288,0.66
                        c-0.415,0.28-0.799,0.608-1.152,0.982c-0.354,0.375-0.672,0.768-0.955,1.18v9.658h-2.729v-14.6h2.638V120.812z"/>
                      <path fill="#B2B3B3" d="M226.34,129.684c0.525,0.431,1.188,0.777,1.986,1.039c0.798,0.263,1.652,0.393,2.562,0.393
                        c1.132,0,1.986-0.173,2.563-0.519c0.576-0.347,0.864-0.791,0.864-1.334c0-0.281-0.056-0.543-0.167-0.786
                        s-0.313-0.478-0.606-0.702s-0.692-0.444-1.197-0.66c-0.506-0.215-1.163-0.453-1.971-0.716c-1.496-0.468-2.607-1.024-3.335-1.67
                        c-0.729-0.646-1.092-1.54-1.092-2.682c0-0.562,0.137-1.086,0.409-1.572s0.667-0.913,1.183-1.277
                        c0.516-0.365,1.142-0.65,1.88-0.856c0.737-0.206,1.581-0.309,2.531-0.309c1.031,0,1.936,0.098,2.714,0.294
                        c0.777,0.197,1.48,0.445,2.107,0.744l-0.759,2.078c-0.545-0.3-1.167-0.543-1.864-0.73c-0.697-0.187-1.471-0.28-2.319-0.28
                        c-1.052,0-1.85,0.196-2.396,0.59c-0.546,0.393-0.818,0.823-0.818,1.291c0,0.225,0.046,0.431,0.137,0.618
                        c0.091,0.187,0.258,0.364,0.5,0.533c0.242,0.168,0.565,0.337,0.97,0.505c0.404,0.169,0.91,0.347,1.517,0.534
                        c0.99,0.318,1.829,0.641,2.517,0.969c0.687,0.327,1.243,0.688,1.667,1.081c0.425,0.393,0.728,0.818,0.91,1.277
                        c0.182,0.459,0.272,0.969,0.272,1.53c0,0.655-0.151,1.245-0.455,1.769c-0.303,0.524-0.728,0.969-1.273,1.334
                        c-0.545,0.364-1.208,0.646-1.985,0.842c-0.778,0.196-1.643,0.295-2.593,0.295c-1.213,0-2.294-0.141-3.244-0.421
                        c-0.95-0.281-1.738-0.646-2.364-1.096L226.34,129.684z"/>
                    </g>
                  </g>
                </g>
                </svg>
              <svg version="1.1" id="peta-line" alt="Line" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                width="158px" height="8.5px" viewBox="0 0 158 8.5" enable-background="new 0 0 158 8.5" xml:space="preserve">
                <g>
                  <path fill="#00D1C3" d="M3.5,4.116c12-0.932,25.356-1.166,37.955-1.463c12.6-0.213,25.277,0.149,37.876,0.325l37.584,0.487
                    l37.585,0.65V4.44l-37.545,0.65L79.284,5.578C66.686,5.754,53.991,6.116,41.393,5.903C28.793,5.606,15.5,5.372,3.5,4.44V4.116z"/>
                </g>
              </svg>
            </div>
            <button class="action-btn" @click="${this.nextSection}">See more ...</button>
          </div>
        </section>

        <section id="section-2" class="v-center">

      
          <svg id="pin-up" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 2.5 55.25 69.125" enable-background="new 0 2.5 55.25 69.125" xml:space="preserve">
            <g>
		          <polygon fill="#989898" points="54.214,70.247 32.961,46.038 38.488,42.131 		"/>
              <path fill="#EF3835" d="M2.949,17.146c3.182,0.906,6.088,2.816,8.476,5.197c1.189,1.203,2.264,2.518,3.232,3.907
                c0.95,1.401,1.792,2.873,2.538,4.389c1.481,3.041,2.706,6.266,3.451,9.55C21.344,43.483,21,46.835,22,50.206v0.031
                c0,0.082-0.164,0.169-0.177,0.247l-0.162,0.482l-2.091-1.601c2.56-1.082,5.038-2.318,7.517-3.59
                c1.234-0.645,2.444-1.32,3.647-2.024c1.208-0.693,2.404-1.404,3.579-2.155c2.341-1.512,4.644-3.09,6.8-4.866
                c2.161-1.767,4.2-3.697,5.914-5.968c-1.197,2.593-3.014,4.887-4.978,6.977c-1.99,2.076-4.163,3.979-6.467,5.694
                c-1.15,0.861-2.32,1.695-3.519,2.489c-1.205,0.782-2.427,1.534-3.668,2.257c-2.474,1.462-5.054,2.737-7.685,3.906
                c-0.744,0.331-1.616-0.004-1.946-0.748c-0.12-0.271-0.153-0.557-0.109-0.83l0.004-0.023L18.733,50l-0.019,0.279
                c-0.127-3.184-0.415-6.372-1.058-9.496c-0.603-3.129-1.484-6.204-2.68-9.15c-0.593-1.475-1.276-2.914-2.064-4.293
                c-0.769-1.391-1.642-2.726-2.637-3.97C8.319,20.852,5.853,18.71,2.949,17.146z"/>
              <path fill="#EF3835" d="M20.765,4.941c0.729,3.148,2.027,6.007,3.753,8.572c0.879,1.271,1.81,2.503,2.861,3.638
                c1.032,1.149,2.129,2.246,3.305,3.26c2.327,2.054,4.849,3.908,7.507,5.589c2.662,1.68,5.445,3.19,8.315,4.67
                c-3.178-0.591-6.26-1.69-9.177-3.134c-2.914-1.45-5.683-3.25-8.142-5.438c-1.216-1.107-2.379-2.281-3.426-3.562
                c-1.027-1.293-1.991-2.649-2.749-4.125C21.452,11.501,20.554,8.177,20.765,4.941z"/>
              <path fill="#EF3835" d="M20.563,3.67c0.27,0.429,0.042,0.952-0.151,1.364c-0.213,0.43-0.474,0.833-0.757,1.22
                c-0.565,0.774-1.183,1.512-1.843,2.21c-1.305,1.413-2.705,2.747-4.169,4.017c-1.47,1.264-3.019,2.451-4.709,3.473
                c-0.853,0.498-1.727,0.975-2.691,1.357c-0.487,0.184-0.986,0.358-1.573,0.459c-0.296,0.045-0.607,0.084-1,0.051
                c-0.202-0.024-0.418-0.058-0.674-0.165c-0.247-0.095-0.543-0.301-0.755-0.582l-0.09-0.141l-0.022-0.039L2.1,16.84l-0.065-0.149
                c-0.039-0.097-0.077-0.218-0.092-0.3l-0.029-0.21c-0.024-0.277,0.008-0.494,0.048-0.693c0.085-0.384,0.217-0.668,0.351-0.937
                c0.271-0.531,0.59-0.953,0.913-1.362c0.652-0.807,1.375-1.493,2.106-2.156c1.485-1.304,3.083-2.418,4.739-3.439
                c1.649-1.007,3.349-1.938,5.09-2.752c0.866-0.417,1.756-0.783,2.665-1.087c0.454-0.152,0.918-0.28,1.392-0.352
                C19.669,3.343,20.236,3.284,20.563,3.67z M20.563,3.67c-0.333-0.38-0.894-0.312-1.342-0.247c-0.47,0.08-0.923,0.237-1.367,0.406
                C16.967,4.181,16.13,4.64,15.31,5.121c-1.629,0.988-3.137,2.149-4.575,3.391c-1.423,1.24-2.791,2.551-4.043,3.913
                c-0.634,0.671-1.222,1.382-1.737,2.088c-0.256,0.35-0.485,0.716-0.645,1.039c-0.078,0.158-0.137,0.316-0.151,0.4
                c-0.004,0.035-0.004,0.058-0.003,0.019c0-0.011-0.002-0.022-0.006-0.032c-0.004-0.024-0.019-0.09-0.041-0.129
                c-0.011-0.022-0.023-0.044-0.038-0.064c-0.013-0.017,0.009,0.018,0.012,0.024l0.022,0.039l0.011,0.02l0.005,0.01
                c-0.028-0.042,0.071,0.109-0.061-0.093c-0.162-0.206-0.273-0.163-0.168-0.155c0.084,0.011,0.253,0.002,0.428-0.024
                c0.357-0.05,0.774-0.16,1.186-0.297c0.832-0.273,1.685-0.622,2.518-1.021c1.681-0.78,3.339-1.687,4.957-2.671
                c1.613-0.991,3.18-2.081,4.612-3.335c0.706-0.636,1.397-1.296,2-2.035c0.295-0.373,0.583-0.757,0.8-1.182
                C20.591,4.617,20.825,4.102,20.563,3.67z"/>
            </g>

          </svg>
          <div id="pin-notes">
            Templates are great...  when you want a copy of a standard site.
            <br>
            <br>
            However, if you require special features or a unique user experience, 
            then a customized web app maybe the right choice for you.
            <br>
            <br>
            For enquiries, throw me a message.
            <br>
            <br>
            <svg version="1.1" id="note-plane" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              width="80px" height="83.33px" viewBox="0 0 100 83.33" enable-background="new 0 0 100 83.33" xml:space="preserve">
              <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="28.2446" y1="77.3154" x2="92.0403" y2="-0.6868">
                <stop  offset="0.0834" style="stop-color:#3FA9F5"/>
                <stop  offset="1" style="stop-color:#2E3192"/>
              </linearGradient>
              <path fill="url(#SVGID_1_)" d="M98,3.44L80.52,74.91l-31.3-16.01L97.96,3.55L45.32,58.01c-0.02,0.02-0.04,0.04-0.06,0.06
                c0,0-0.01,0.01-0.01,0.01c-0.18,0.15-0.33,0.34-0.44,0.56L37.93,72.6L36.73,55L98,3.44L34.62,52.25L8.33,41.67L97.89,3.51
                L3.23,40.03c-0.02,0.01-0.04,0.02-0.06,0.02c-0.89,0.38-1.31,1.41-0.93,2.3c0.06,0.15,0.15,0.28,0.24,0.4
                c0.19,0.29,0.47,0.54,0.82,0.68l29.94,12.04l1.63,23.87c0.05,0.79,0.63,1.45,1.41,1.6c0.11,0.02,0.23,0.03,0.34,0.03
                c0.47,0,0.92-0.19,1.25-0.53c0.45-0.33,1.14-1.09,2.81-2.86c1.29-1.36,3.01-3.26,5.23-5.65c2.75-2.96,5.47-5.9,6.79-7.33
                l28.17,14.41c0.8,0.41,1.76,0.14,2.25-0.58c0.15-0.21,0.27-0.44,0.32-0.71L98,3.44z M43.33,69.59l3.85-7.8l2.29,1.17
                C47.54,65.04,45.37,67.39,43.33,69.59z"/>
            </svg>

          </div>

        </section>

        

        <section id="section-3" class="end-section h-center">
          <svg version="1.1" id="logo-second" alt="Peta logo main" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" 
                  height="80%" viewBox="0 0 320 113" enable-background="new 0 0 320 113" xml:space="preserve">
                <g id="Layer_1">
                  <g>
                    <g>
                      <path fill="#0BA998" d="M22.54,46.98c0.31,0.32,0.88,0.67,1.56,1c0.68,0.32,1.44,0.61,2.23,0.84c1.59,0.48,3.3,0.78,5.02,0.9
                        c3.43,0.24,6.95-0.25,10.01-1.75c3.07-1.47,5.61-4.04,7.22-7.31c1.64-3.26,2.41-7.07,2.66-10.91c0.03-0.48,0.05-0.96,0.07-1.45
                        c-0.01-0.46-0.04-0.98-0.08-1.44c-0.09-0.94-0.22-1.9-0.43-2.83c-0.41-1.87-1.07-3.68-1.98-5.36c-1.82-3.38-4.67-6.17-8.02-8.08
                        c-3.35-1.92-7.2-3.01-11.09-3.12c-1.94-0.06-3.9,0.16-5.78,0.68c-1.87,0.53-3.67,1.4-5.16,2.68c1.45-1.32,3.24-2.24,5.11-2.82
                        c1.88-0.57,3.86-0.84,5.82-0.83c3.94,0.02,7.88,1.02,11.37,2.9c3.49,1.87,6.52,4.69,8.52,8.17c1,1.73,1.74,3.62,2.22,5.56
                        c0.24,0.98,0.41,1.96,0.53,2.96c0.07,0.52,0.08,0.98,0.14,1.49c0.06,0.49,0.11,0.99,0.16,1.49c0.34,3.96,0.18,8.09-1.12,12.11
                        c-0.65,2-1.6,3.96-2.89,5.73c-1.28,1.78-2.9,3.35-4.73,4.61c-3.68,2.55-8.06,3.84-12.43,4.21c-2.2,0.18-4.41,0.14-6.65-0.18
                        c-1.12-0.16-2.26-0.38-3.42-0.72c-1.15-0.34-2.4-0.8-3.68-1.61L22.54,46.98z"/>
                      <path fill="#43C0B9" d="M39.97,39.71l-4.54-8.57l6.34-4.45c0,0-2.64-2.06-3.52-2.63c-1.75-1.16-3.61-2.16-5.53-3.02
                        c-1.91-0.85-3.9-1.51-5.94-1.99c-2.02-0.48-4.15-0.8-6.14-0.85l0.07-3.38c2.39,0.09,4.62,0.47,6.86,1.01
                        c2.22,0.55,4.4,1.3,6.49,2.25c2.07,0.95,4.07,2.05,5.97,3.33c0.95,0.64,1.87,1.32,2.76,2.05c0.9,0.75,3.78,3.73,3.78,3.73
                        l-7.5,5.1L39.97,39.71z"/>
                      <path fill="#0BA998" d="M20.66,18.23c0.83,3.97,1.45,6.43,1.86,10.51c0.14,2.04,0.19,4.07,0.23,6.1c0.03,1.5,0.04,4.49,0.04,4.49
                        l8.3-4.64l3.51,8.06l5.28-3.17l1.56,3l-6.83,3.02l-1.22,0.54l-0.45-1.15l-2.9-7.33l-9.12,4.8c0,0,0.34-9.63,0.3-13.64
                        C21.24,24.78,21.06,22.26,20.66,18.23z"/>
                      <path fill="#43C0B9" d="M12.2,9.03c1.9,4.41,2.9,9.21,3.51,14.02c0.59,4.82,0.76,9.69,0.67,14.56
                        c-0.21,9.74-1.42,19.46-3.53,29.1l-8.09-2.41c3.3-8.69,5.84-17.79,7.42-27.07c0.78-4.64,1.31-9.34,1.42-14.06
                        C13.7,18.43,13.43,13.66,12.2,9.03z"/>
                    </g>
                    <g>
                      <path fill="#43C0B9" d="M110.58,38.78c-0.77,0-1.47,0.06-2.1,0.18c-0.17-0.67-0.38-1.13-0.63-1.39s-0.74-0.39-1.47-0.39
                        c-2.3,0-4.47,0.66-6.52,1.98c-2.05,1.32-3.69,3.06-4.92,5.22c-1.16,2.04-1.77,4.21-1.84,6.48c-0.71,0.65-1.37,1.2-1.96,1.63
                        c-1.1,0.79-2.37,1.18-3.8,1.18c-1.77,0-3.06-0.64-3.87-1.91c-0.82-1.27-1.22-3.48-1.22-6.63c0-0.85,0.03-2.03,0.1-3.54h6.1
                        c3.76,0,5.65-1.04,5.65-3.13c0-0.58-0.12-0.97-0.35-1.18c-0.23-0.21-0.62-0.32-1.15-0.32h-5.6c4.33-3.48,6.5-7.57,6.5-12.26
                        c0-1.7-0.4-3.1-1.2-4.22c-0.8-1.12-1.9-1.68-3.3-1.68c-1.9,0-3.67,0.82-5.32,2.45c-1.65,1.64-3.06,3.83-4.22,6.59
                        c-1.17,2.76-2.03,5.8-2.6,9.13H74c-1.5,0-2.25,0.85-2.25,2.54c0,0.73,0.17,1.26,0.53,1.59c0.35,0.33,1.02,0.5,2.02,0.5h1.95
                        c-0.1,1.36-0.15,2.71-0.15,4.04c0,0.98,0.05,1.9,0.14,2.77c-0.3,0.1-0.58,0.27-0.83,0.54c-1.4,1.54-3.06,2.79-4.97,3.75
                        c-1.92,0.95-3.62,1.43-5.12,1.43c-2.33,0-3.92-0.7-4.75-2.09c3.93-0.88,6.72-2.07,8.37-3.57c1.65-1.5,2.47-3.29,2.47-5.38
                        c0-1.79-0.58-3.2-1.75-4.25c-1.17-1.04-2.72-1.57-4.65-1.57c-2.17,0-4.11,0.53-5.82,1.59c-1.72,1.06-3.06,2.49-4.02,4.29
                        c-0.97,1.8-1.45,3.75-1.45,5.84c0,3,0.97,5.36,2.9,7.09c1.93,1.73,4.65,2.59,8.15,2.59c2.56,0,4.97-0.57,7.22-1.73
                        c2.25-1.15,3.99-2.41,5.22-3.77c0.04-0.04,0.07-0.1,0.11-0.14c1.53,3.75,4.52,5.64,8.99,5.64c2.2,0,4.15-0.51,5.85-1.54
                        c0.71-0.43,1.39-0.92,2.05-1.45c0.2,0.33,0.42,0.66,0.67,0.95c1.18,1.36,2.72,2.04,4.62,2.04c2.53,0,4.31-1.3,5.35-3.91
                        l-0.05,0.73c0,1.12,0.27,1.93,0.82,2.43s1.39,0.75,2.52,0.75c1.17,0,2.01-0.25,2.52-0.75s0.77-1.32,0.77-2.48
                        c0-2.09,0.1-3.97,0.3-5.66c0.2-1.68,0.48-3.44,0.85-5.29c0.33-1.73,0.5-2.94,0.5-3.63c0-0.67-0.22-1.18-0.65-1.54
                        C111.99,38.97,111.38,38.78,110.58,38.78z M84.21,29.59c0.55-2.01,1.13-3.59,1.75-4.72c0.62-1.14,1.17-1.7,1.67-1.7
                        c0.73,0,1.1,0.89,1.1,2.68c0,1.57-0.49,3.32-1.47,5.25c-0.98,1.92-2.46,3.66-4.42,5.2C83.21,33.83,83.67,31.6,84.21,29.59z
                        M59.75,48.41c0-1.85,0.42-3.42,1.27-4.72c0.85-1.3,1.89-1.95,3.12-1.95c0.6,0,1.07,0.17,1.42,0.52
                        c0.35,0.35,0.52,0.81,0.52,1.39c0,1.12-0.57,2.1-1.7,2.95c-1.13,0.85-2.68,1.48-4.65,1.91V48.41z M106.03,46.01
                        c-0.6,3.03-1.28,5.13-2.05,6.31s-1.62,1.77-2.55,1.77c-0.63,0-1.14-0.23-1.52-0.7c-0.38-0.47-0.57-1.16-0.57-2.07
                        c0-1.7,0.35-3.3,1.05-4.81c0.7-1.51,1.63-2.73,2.8-3.66c1.17-0.92,2.43-1.4,3.8-1.43L106.03,46.01z"/>
                      <path fill="#43C0B9" d="M166.83,23.84c-1.22-1.33-2.84-2.32-4.87-2.98c-2.03-0.65-4.27-0.98-6.7-0.98
                        c-3.27,0-6.11,0.47-8.52,1.41c-2.42,0.94-4.26,2.26-5.55,3.95c-1.28,1.7-1.92,3.63-1.92,5.81c0,2.09,0.58,3.84,1.72,5.25
                        s2.53,2.52,4.15,3.34c1.62,0.82,3.71,1.68,6.27,2.59c2,0.73,3.55,1.34,4.65,1.84c1.1,0.5,2.02,1.12,2.75,1.86
                        c0.73,0.74,1.1,1.63,1.1,2.66c0,1.67-0.8,2.96-2.4,3.88c-1.6,0.92-3.82,1.39-6.65,1.39c-2.73,0-4.86-0.33-6.37-1
                        c-1.52-0.67-2.27-1.68-2.27-3.04c0-0.33,0.02-0.65,0.07-0.95c0.05-0.3,0.08-0.51,0.08-0.64c0.1-0.45,0.15-0.98,0.15-1.59
                        c0-0.67-0.28-1.21-0.85-1.64c-0.57-0.42-1.3-0.64-2.2-0.64c-1.23,0-2.17,0.44-2.8,1.32c-0.63,0.88-0.95,2.15-0.95,3.82
                        c0,3.15,1.33,5.49,4,7.02c2.67,1.53,6.21,2.29,10.64,2.29c5,0,8.99-0.96,11.97-2.88c2.98-1.92,4.47-4.67,4.47-8.24
                        c0-1.91-0.54-3.51-1.63-4.81c-1.08-1.3-2.39-2.33-3.92-3.09c-1.53-0.76-3.57-1.6-6.1-2.54c-2.1-0.76-3.73-1.41-4.9-1.95
                        c-1.17-0.55-2.14-1.23-2.92-2.04c-0.78-0.82-1.17-1.8-1.17-2.95c0-1.73,0.76-3.07,2.27-4.02c1.52-0.95,3.71-1.43,6.57-1.43
                        c2.03,0,3.73,0.33,5.1,1c1.37,0.67,2.05,1.64,2.05,2.91c0,0.24-0.05,0.82-0.15,1.73c-0.1,0.48-0.15,1.01-0.15,1.59
                        c0,0.67,0.29,1.24,0.88,1.73c0.58,0.48,1.31,0.73,2.17,0.73c1.23,0,2.17-0.48,2.8-1.45c0.63-0.97,0.95-2.41,0.95-4.31
                        C168.66,26.81,168.05,25.17,166.83,23.84z"/>
                      <path fill="#43C0B9" d="M288.75,43.51h-0.45c0.37-1.6,0.55-2.69,0.55-3.27c0-0.97-0.26-1.7-0.77-2.2s-1.26-0.75-2.22-0.75
                        c-1.07,0-2.25,0.35-3.55,1.04c-1.3,0.7-2.63,1.86-4,3.5s-2.6,3.76-3.7,6.36c0.13-1.33,0.3-2.45,0.5-3.36
                        c0.2-0.91,0.47-1.97,0.8-3.18c0.5-1.7,0.75-2.79,0.75-3.27c0-0.42-0.13-0.71-0.4-0.86c-0.27-0.15-0.73-0.23-1.4-0.23
                        c-1.23,0-2.19,0.2-2.87,0.61c-0.68,0.41-1.17,1.05-1.47,1.93c-1.15,3.29-1.75,6.76-1.83,10.39c-0.99,1.03-1.81,1.82-2.47,2.35
                        c-0.9,0.74-1.67,1.11-2.3,1.11c-0.4,0-0.72-0.14-0.97-0.41s-0.37-0.64-0.37-1.09c0-1.36,0.38-3.79,1.15-7.27
                        c0.5-2.39,0.75-3.91,0.75-4.54c0-1.06-0.82-1.59-2.45-1.59c-0.77,0-1.5,0.06-2.2,0.18c-0.17-0.67-0.38-1.13-0.63-1.39
                        s-0.74-0.39-1.47-0.39c-2.3,0-4.47,0.66-6.52,1.98c-2.05,1.32-3.69,3.06-4.92,5.22c-0.09,0.16-0.17,0.32-0.25,0.48
                        c-2.1,0.39-3.78,0.62-5.04,0.68c0.8-1.76,1.2-3.32,1.2-4.68c0-1.18-0.3-2.08-0.9-2.7c-0.6-0.62-1.38-0.93-2.35-0.93
                        c-1.1,0-2.08,0.3-2.95,0.91c-0.87,0.61-1.55,1.39-2.05,2.36c-0.5,0.97-0.75,1.97-0.75,3c0,1.21,0.25,2.26,0.75,3.13
                        c-1,1.61-2.5,3.76-4.5,6.45c-0.47-5.57-0.7-10.1-0.7-13.58c0-0.85-0.18-1.44-0.53-1.77c-0.35-0.33-0.97-0.5-1.87-0.5
                        c-0.73,0-1.36,0.16-1.87,0.48c-0.52,0.32-0.96,0.93-1.32,1.84c-1.9,4.69-3.98,9.21-6.25,13.54c-0.47-5.57-0.7-10.1-0.7-13.58
                        c0-0.82-0.21-1.4-0.63-1.75c-0.42-0.35-1.06-0.52-1.92-0.52c-1.13,0-1.98,0.2-2.55,0.59c-0.57,0.39-0.87,1.09-0.9,2.09v1.59
                        c0,2.39,0.11,5.08,0.34,8.06c-1.27,1.26-2.71,2.31-4.36,3.13c-1.92,0.95-3.62,1.43-5.12,1.43c-2.33,0-3.92-0.7-4.75-2.09
                        c3.93-0.88,6.72-2.07,8.37-3.57c1.65-1.5,2.47-3.29,2.47-5.38c0-1.79-0.58-3.2-1.75-4.25c-1.17-1.04-2.72-1.57-4.65-1.57
                        c-2.17,0-4.11,0.53-5.82,1.59c-1.72,1.06-3.06,2.49-4.02,4.29c-0.97,1.8-1.45,3.75-1.45,5.84c0,0.64,0.05,1.25,0.14,1.84
                        c-0.72,0.67-1.39,1.23-1.99,1.66c-1.1,0.79-2.37,1.18-3.8,1.18c-1.77,0-3.06-0.64-3.87-1.91c-0.82-1.27-1.22-3.48-1.22-6.63
                        c0-0.85,0.03-2.03,0.1-3.54h6.1c3.76,0,5.65-1.04,5.65-3.13c0-0.58-0.12-0.97-0.35-1.18c-0.23-0.21-0.62-0.32-1.15-0.32h-5.6
                        c4.33-3.48,6.5-7.57,6.5-12.26c0-1.7-0.4-3.1-1.2-4.22c-0.8-1.12-1.9-1.68-3.3-1.68c-1.9,0-3.67,0.82-5.32,2.45
                        c-1.65,1.64-3.06,3.83-4.22,6.59c-1.17,2.76-2.03,5.8-2.6,9.13h-2.85c-1.5,0-2.25,0.85-2.25,2.54c0,0.73,0.17,1.26,0.53,1.59
                        c0.35,0.33,1.02,0.5,2.02,0.5h1.95c-0.1,1.36-0.15,2.71-0.15,4.04c0,8.69,3.4,13.04,10.2,13.04c2.2,0,4.15-0.51,5.85-1.54
                        c0.98-0.59,1.9-1.29,2.79-2.07c0.29,0.36,0.6,0.71,0.96,1.02c1.93,1.73,4.65,2.59,8.15,2.59c2.57,0,4.97-0.57,7.22-1.73
                        c1.34-0.68,2.47-1.41,3.45-2.17c0.26,1.02,0.62,1.85,1.1,2.44c0.78,0.97,1.86,1.45,3.22,1.45c1.1,0,2.06-0.26,2.87-0.77
                        c0.82-0.51,1.63-1.44,2.45-2.77c0.82-1.33,1.74-3.27,2.77-5.81l0.25,3.32c0.17,2.15,0.59,3.7,1.27,4.63
                        c0.68,0.94,1.69,1.41,3.02,1.41c1.2,0,2.27-0.32,3.22-0.95c0.95-0.64,2.09-1.82,3.42-3.54c1.17-1.51,2.23-2.98,3.2-4.41
                        c1.3,0.36,2.68,0.54,4.15,0.54c0.8,0,1.59-0.05,2.35-0.15c-0.03,0.37-0.05,0.73-0.05,1.11c0,2.21,0.62,4,1.85,5.36
                        c1.23,1.36,2.88,2.04,4.95,2.04c2.63,0,4.81-1.27,6.55-3.82c0.47,1.18,1.16,2.11,2.07,2.79c0.92,0.68,1.92,1.02,3.02,1.02
                        c1.43,0,2.92-0.53,4.45-1.59c0.6-0.42,1.19-0.88,1.76-1.37c0.1,0.43,0.22,0.82,0.36,1.15c0.52,1.21,1.41,1.82,2.67,1.82
                        c1.07,0,1.83-0.24,2.3-0.73c0.47-0.48,0.88-1.45,1.25-2.91c0.5-1.85,1.15-3.6,1.95-5.27c0.8-1.67,1.6-3.01,2.4-4.02
                        c0.8-1.01,1.43-1.52,1.9-1.52c0.17,0,0.31,0.06,0.42,0.18c0.12,0.12,0.17,0.3,0.17,0.55c0,0.18-0.03,0.52-0.1,1
                        c-0.07,0.67-0.1,1.29-0.1,1.86c0,1.03,0.33,1.85,0.97,2.45c0.65,0.61,1.44,0.91,2.37,0.91c1.17,0,2.23-0.38,3.2-1.14
                        c0.97-0.76,1.72-1.7,2.25-2.82c0.2-0.45,0.3-0.88,0.3-1.27c0-0.7-0.26-1.28-0.77-1.75C290.1,43.74,289.48,43.51,288.75,43.51z
                        M180.67,29.59c0.55-2.01,1.13-3.59,1.75-4.72c0.62-1.14,1.17-1.7,1.67-1.7c0.73,0,1.1,0.89,1.1,2.68c0,1.57-0.49,3.32-1.47,5.25
                        c-0.98,1.92-2.46,3.66-4.42,5.2C179.67,33.83,180.13,31.6,180.67,29.59z M195.49,48.41c0-1.85,0.42-3.42,1.27-4.72
                        c0.85-1.3,1.89-1.95,3.12-1.95c0.6,0,1.08,0.17,1.42,0.52c0.35,0.35,0.52,0.81,0.52,1.39c0,1.12-0.57,2.1-1.7,2.95
                        c-1.13,0.85-2.68,1.48-4.65,1.91V48.41z M256.56,49.78c-0.3,1.3-0.78,2.35-1.45,3.13c-0.67,0.79-1.37,1.18-2.1,1.18
                        c-0.73,0-1.31-0.23-1.72-0.68c-0.42-0.45-0.63-1.15-0.63-2.09c0-1.7,0.35-3.3,1.05-4.81c0.7-1.51,1.64-2.73,2.82-3.66
                        c1.18-0.92,2.47-1.4,3.87-1.43L256.56,49.78z"/>
                      <path fill="#43C0B9" d="M307.64,41.6c3.76,0,5.65-1.04,5.65-3.13c0-0.58-0.12-0.97-0.35-1.18c-0.23-0.21-0.62-0.32-1.15-0.32
                        h-5.6c4.33-3.48,6.5-7.57,6.5-12.26c0-1.7-0.4-3.1-1.2-4.22c-0.8-1.12-1.9-1.68-3.3-1.68c-1.9,0-3.67,0.82-5.32,2.45
                        c-1.65,1.64-3.06,3.83-4.22,6.59c-1.17,2.76-2.03,5.8-2.6,9.13h-2.85c-1.5,0-2.25,0.85-2.25,2.54c0,0.73,0.17,1.26,0.53,1.59
                        c0.35,0.33,1.02,0.5,2.02,0.5h1.95c-0.1,1.36-0.15,2.71-0.15,4.04c0,3.88,0.73,7.02,2.2,9.43c1.47,2.41,3.78,3.61,6.95,3.61
                        c2.2,0,4.17-0.65,5.9-1.95c0.5-0.36,0.88-0.82,1.12-1.36c0.25-0.54,0.38-1.1,0.38-1.68c0-0.57-0.13-1.07-0.38-1.48
                        c-0.25-0.41-0.57-0.61-0.97-0.61c-0.37,0-0.73,0.15-1.1,0.45c-0.6,0.48-1.27,0.88-2,1.18c-0.73,0.3-1.37,0.45-1.9,0.45
                        c-1.03,0-1.84-0.26-2.42-0.79c-0.58-0.53-1-1.41-1.25-2.63c-0.25-1.23-0.38-2.93-0.38-5.11c0-0.85,0.03-2.03,0.1-3.54H307.64z
                        M303.41,29.59c0.55-2.01,1.13-3.59,1.75-4.72c0.62-1.14,1.17-1.7,1.67-1.7c0.73,0,1.1,0.89,1.1,2.68c0,1.57-0.49,3.32-1.47,5.25
                        c-0.98,1.92-2.46,3.66-4.42,5.2C302.4,33.83,302.86,31.6,303.41,29.59z"/>
                    </g>
                    <path fill="#43C0B9" d="M21,66.59c23-0.57,47.18-0.72,70.81-0.9c23.63-0.13,47.03,0.09,70.65,0.2L304,66.59v0.2l-141.58,0.7
                      c-23.63,0.11-47.08,0.33-70.71,0.2C68.08,67.5,44,67.36,21,66.79V66.59z"/>
                  </g>
                </g>
                <g id="side_details">
                  <g>
                    <path fill="#ABABAB" d="M24.01,79.83c1.15-0.11,2.27-0.2,3.37-0.25c1.1-0.06,2.35-0.08,3.77-0.08c2.2,0,4.09,0.32,5.67,0.95
                      c1.59,0.63,2.9,1.5,3.95,2.61c1.05,1.11,1.82,2.39,2.32,3.86c0.5,1.47,0.75,3.03,0.75,4.68c0,1.65-0.26,3.21-0.77,4.69
                      c-0.51,1.48-1.3,2.77-2.36,3.88c-1.06,1.11-2.42,1.98-4.06,2.63c-1.65,0.64-3.62,0.97-5.91,0.97c-1.39,0-2.6-0.03-3.64-0.08
                      c-1.04-0.06-2.07-0.14-3.09-0.25V79.83z M31.03,82.27c-0.71,0-1.35,0.02-1.94,0.05s-1.12,0.07-1.61,0.12v18.33
                      c0.51,0.07,1.05,0.11,1.61,0.14c0.56,0.02,1.15,0.03,1.76,0.03c1.63,0,3.05-0.23,4.24-0.69c1.19-0.46,2.18-1.11,2.95-1.93
                      c0.77-0.82,1.34-1.81,1.7-2.95s0.55-2.4,0.55-3.78c0-1.22-0.18-2.39-0.55-3.51s-0.93-2.11-1.68-2.98
                      c-0.76-0.87-1.72-1.56-2.89-2.07S32.62,82.27,31.03,82.27z"/>
                    <path fill="#ABABAB" d="M50.24,82.85c-0.63,0-1.18-0.21-1.63-0.63c-0.45-0.42-0.68-0.92-0.68-1.51c0-0.66,0.21-1.17,0.62-1.56
                      c0.41-0.38,0.98-0.58,1.68-0.58c0.63,0,1.18,0.21,1.63,0.63c0.45,0.42,0.68,0.92,0.68,1.51c0,0.66-0.21,1.17-0.62,1.56
                      C51.51,82.66,50.95,82.85,50.24,82.85z M51.89,103.49h-3.29V85.87h3.29V103.49z"/>
                    <path fill="#ABABAB" d="M65.28,85.46c1.1,0,2.08,0.17,2.95,0.51c0.87,0.34,1.62,0.76,2.25,1.25v-1.36h3v17.21
                      c0,1.47-0.21,2.72-0.62,3.76c-0.42,1.04-1.01,1.89-1.77,2.56c-0.77,0.67-1.69,1.16-2.76,1.47c-1.07,0.32-2.27,0.47-3.59,0.47
                      c-1.15,0-2.24-0.12-3.29-0.37c-1.05-0.25-1.99-0.62-2.82-1.12l1.21-2.64c0.59,0.43,1.27,0.78,2.05,1.07
                      c0.78,0.28,1.73,0.42,2.85,0.42c1.73,0,3.09-0.42,4.08-1.25c0.99-0.84,1.48-2.19,1.48-4.07v-2.17c-0.49,0.54-1.19,1.06-2.1,1.54
                      c-0.91,0.49-2.01,0.73-3.27,0.73c-1.07,0-2.12-0.2-3.15-0.59c-1.02-0.4-1.93-0.97-2.71-1.73c-0.78-0.76-1.41-1.68-1.88-2.78
                      c-0.48-1.1-0.71-2.35-0.71-3.78c0-1.31,0.23-2.52,0.7-3.64c0.46-1.12,1.09-2.08,1.88-2.9c0.79-0.81,1.73-1.45,2.8-1.91
                      C62.91,85.69,64.06,85.46,65.28,85.46z M65.65,100.78c0.98,0,1.85-0.2,2.63-0.61s1.41-0.88,1.9-1.42v-9.22
                      c-0.56-0.36-1.21-0.69-1.94-0.98c-0.73-0.29-1.61-0.44-2.63-0.44c-0.76,0-1.48,0.14-2.18,0.42c-0.7,0.28-1.31,0.69-1.85,1.24
                      c-0.54,0.54-0.96,1.2-1.28,1.98c-0.32,0.78-0.48,1.68-0.48,2.69c0,0.97,0.16,1.85,0.48,2.63c0.32,0.78,0.74,1.45,1.28,2
                      c0.54,0.55,1.15,0.98,1.85,1.27C64.13,100.63,64.87,100.78,65.65,100.78z"/>
                    <path fill="#ABABAB" d="M80.79,82.85c-0.63,0-1.18-0.21-1.63-0.63c-0.45-0.42-0.68-0.92-0.68-1.51c0-0.66,0.21-1.17,0.62-1.56
                      c0.41-0.38,0.98-0.58,1.68-0.58c0.63,0,1.18,0.21,1.63,0.63c0.45,0.42,0.68,0.92,0.68,1.51c0,0.66-0.21,1.17-0.62,1.56
                      C82.06,82.66,81.5,82.85,80.79,82.85z M82.44,103.49h-3.29V85.87h3.29V103.49z"/>
                    <path fill="#ABABAB" d="M89.8,80.95h2.52v5.56h5.27v2.64h-5.27v8.51c0,0.66,0.08,1.21,0.24,1.66c0.16,0.45,0.37,0.81,0.64,1.08
                      c0.27,0.27,0.59,0.47,0.97,0.59c0.38,0.12,0.77,0.19,1.19,0.19c0.61,0,1.18-0.09,1.72-0.27c0.54-0.18,1.05-0.4,1.54-0.64
                      l0.91,2.44c-0.49,0.27-1.15,0.54-1.98,0.8c-0.83,0.26-1.7,0.39-2.6,0.39c-1.83,0-3.27-0.55-4.34-1.66
                      c-1.06-1.11-1.59-2.71-1.59-4.81v-8.27h-3.29v-2.64h3.37L89.8,80.95z"/>
                    <path fill="#ABABAB" d="M100.81,94.78c0-1.31,0.22-2.54,0.66-3.68s1.05-2.13,1.85-2.97c0.79-0.84,1.74-1.49,2.85-1.97
                      c1.11-0.47,2.32-0.71,3.64-0.71c1.1,0,2.09,0.18,2.98,0.53c0.89,0.35,1.66,0.79,2.32,1.3v-1.42h3v13.35
                      c0,0.86,0.23,1.42,0.68,1.68c0.45,0.26,0.95,0.39,1.48,0.39l-0.69,2.37c-2.34,0-3.76-0.88-4.24-2.64
                      c-0.29,0.36-0.63,0.72-1.01,1.07c-0.38,0.35-0.81,0.66-1.3,0.93c-0.49,0.27-1.04,0.49-1.65,0.64c-0.61,0.16-1.28,0.24-2.01,0.24
                      c-1.2,0-2.31-0.21-3.35-0.64c-1.04-0.43-1.94-1.04-2.71-1.83c-0.77-0.79-1.38-1.75-1.83-2.86
                      C101.04,97.44,100.81,96.18,100.81,94.78z M110.1,101.18c0.93,0,1.8-0.2,2.63-0.61c0.83-0.41,1.52-0.94,2.09-1.59v-9.45
                      c-0.66-0.43-1.38-0.77-2.18-1.03c-0.79-0.26-1.63-0.39-2.51-0.39c-0.88,0-1.68,0.16-2.42,0.47c-0.73,0.32-1.36,0.76-1.88,1.34
                      c-0.52,0.58-0.93,1.27-1.23,2.08c-0.29,0.81-0.44,1.71-0.44,2.68c0,0.95,0.15,1.82,0.44,2.63c0.29,0.8,0.71,1.49,1.24,2.07
                      c0.54,0.58,1.16,1.02,1.88,1.34C108.46,101.02,109.25,101.18,110.1,101.18z"/>
                    <path fill="#ABABAB" d="M127.78,97.66c0,1.22,0.27,2.12,0.82,2.71s1.31,0.88,2.29,0.88c0.46,0,0.91-0.05,1.35-0.15
                      c0.44-0.1,0.83-0.22,1.17-0.36l0.81,2.47c-0.44,0.18-0.98,0.34-1.63,0.47c-0.65,0.14-1.36,0.2-2.14,0.2
                      c-0.85,0-1.64-0.11-2.36-0.34c-0.72-0.23-1.35-0.58-1.88-1.05c-0.54-0.47-0.96-1.08-1.26-1.83c-0.31-0.75-0.46-1.65-0.46-2.71
                      V78.41h3.29V97.66z"/>
                    <path fill="#ABABAB" d="M148.23,98.78c0.88,0.68,1.87,1.23,2.96,1.66c1.1,0.43,2.38,0.64,3.84,0.64c1.76,0,3.15-0.34,4.17-1.02
                      c1.02-0.68,1.54-1.59,1.54-2.74c0-0.47-0.07-0.92-0.22-1.32c-0.15-0.41-0.43-0.8-0.86-1.17c-0.43-0.37-1.02-0.75-1.79-1.12
                      c-0.77-0.37-1.76-0.77-2.98-1.2c-1.32-0.47-2.43-0.94-3.35-1.41c-0.91-0.46-1.65-0.97-2.21-1.52c-0.56-0.55-0.96-1.16-1.21-1.83
                      c-0.24-0.67-0.37-1.43-0.37-2.29c0-0.84,0.19-1.63,0.57-2.37c0.38-0.75,0.94-1.39,1.68-1.95c0.74-0.55,1.66-0.99,2.74-1.3
                      c1.08-0.32,2.32-0.47,3.71-0.47c1.51,0,2.87,0.14,4.06,0.42c1.19,0.28,2.38,0.68,3.55,1.2l-1.28,2.74
                      c-0.83-0.45-1.8-0.83-2.91-1.14c-1.11-0.3-2.27-0.46-3.49-0.46c-1.61,0-2.88,0.29-3.81,0.86c-0.93,0.58-1.39,1.34-1.39,2.29
                      c0,0.45,0.07,0.85,0.2,1.2c0.13,0.35,0.4,0.69,0.81,1.02s0.96,0.66,1.68,1c0.72,0.34,1.68,0.71,2.87,1.12
                      c1.41,0.5,2.61,1.01,3.59,1.52c0.98,0.52,1.76,1.07,2.34,1.66c0.59,0.59,1.01,1.22,1.26,1.9c0.26,0.68,0.38,1.42,0.38,2.24
                      c0,1.11-0.23,2.09-0.7,2.95c-0.46,0.86-1.1,1.59-1.92,2.19c-0.82,0.6-1.8,1.05-2.95,1.36c-1.15,0.3-2.39,0.46-3.73,0.46
                      c-1.78,0-3.4-0.22-4.87-0.66c-1.46-0.44-2.7-1.02-3.7-1.75L148.23,98.78z"/>
                    <path fill="#ABABAB" d="M167.62,94.68c0-1.42,0.24-2.71,0.73-3.85c0.49-1.14,1.15-2.11,1.99-2.9c0.84-0.79,1.84-1.4,3-1.83
                      s2.41-0.64,3.75-0.64c1.32,0,2.55,0.21,3.71,0.64c1.16,0.43,2.16,1.04,3.02,1.83c0.85,0.79,1.52,1.75,2.01,2.88
                      c0.49,1.13,0.73,2.39,0.73,3.79c0,1.4-0.24,2.67-0.71,3.81c-0.48,1.14-1.14,2.12-1.99,2.93c-0.85,0.81-1.86,1.44-3.02,1.88
                      c-1.16,0.44-2.41,0.66-3.75,0.66c-1.37,0-2.63-0.23-3.79-0.68s-2.16-1.08-3-1.9c-0.84-0.81-1.5-1.78-1.98-2.91
                      S167.62,96.03,167.62,94.68z M183.21,94.68c0-0.88-0.15-1.72-0.46-2.51c-0.31-0.79-0.73-1.48-1.28-2.07
                      c-0.55-0.59-1.2-1.06-1.94-1.41c-0.74-0.35-1.56-0.52-2.43-0.52c-1.93,0-3.43,0.59-4.5,1.76c-1.07,1.18-1.61,2.73-1.61,4.68
                      c0,0.88,0.15,1.72,0.46,2.52c0.3,0.8,0.73,1.5,1.28,2.1c0.55,0.6,1.2,1.07,1.94,1.42c0.74,0.35,1.56,0.53,2.43,0.53
                      c0.95,0,1.8-0.16,2.56-0.47c0.76-0.32,1.4-0.76,1.92-1.34c0.52-0.58,0.93-1.26,1.21-2.05C183.07,96.53,183.21,95.65,183.21,94.68z
                      "/>
                    <path fill="#ABABAB" d="M194.08,97.66c0,1.22,0.27,2.12,0.82,2.71s1.31,0.88,2.29,0.88c0.46,0,0.91-0.05,1.35-0.15
                      c0.44-0.1,0.83-0.22,1.17-0.36l0.81,2.47c-0.44,0.18-0.98,0.34-1.63,0.47c-0.65,0.14-1.36,0.2-2.14,0.2
                      c-0.85,0-1.64-0.11-2.36-0.34c-0.72-0.23-1.35-0.58-1.88-1.05c-0.54-0.47-0.96-1.08-1.26-1.83c-0.31-0.75-0.46-1.65-0.46-2.71
                      V78.41h3.29V97.66z"/>
                    <path fill="#ABABAB" d="M220.09,103.66c-1.17,0-2.1-0.21-2.78-0.64c-0.68-0.43-1.16-1.05-1.43-1.86
                      c-0.85,0.81-1.84,1.47-2.95,1.98c-1.11,0.51-2.32,0.76-3.64,0.76c-0.83,0-1.63-0.12-2.4-0.36s-1.45-0.62-2.05-1.14
                      c-0.6-0.52-1.07-1.2-1.43-2.03c-0.35-0.84-0.53-1.85-0.53-3.05V85.87h3.29v11.05c0,1.38,0.31,2.43,0.93,3.17
                      c0.62,0.73,1.56,1.1,2.8,1.1c1.02,0,2-0.19,2.93-0.56c0.93-0.37,1.76-0.88,2.49-1.51V85.87h3.29v13.05c0,0.5,0.06,0.9,0.18,1.2
                      c0.12,0.31,0.28,0.54,0.48,0.71c0.19,0.17,0.43,0.29,0.7,0.36c0.27,0.07,0.54,0.1,0.8,0.1L220.09,103.66z"/>
                    <path fill="#ABABAB" d="M226.68,80.95h2.52v5.56h5.27v2.64h-5.27v8.51c0,0.66,0.08,1.21,0.24,1.66c0.16,0.45,0.37,0.81,0.64,1.08
                      c0.27,0.27,0.59,0.47,0.97,0.59c0.38,0.12,0.77,0.19,1.19,0.19c0.61,0,1.18-0.09,1.72-0.27c0.54-0.18,1.05-0.4,1.54-0.64
                      l0.91,2.44c-0.49,0.27-1.15,0.54-1.98,0.8c-0.83,0.26-1.7,0.39-2.6,0.39c-1.83,0-3.27-0.55-4.34-1.66
                      c-1.06-1.11-1.59-2.71-1.59-4.81v-8.27h-3.29v-2.64h3.37L226.68,80.95z"/>
                    <path fill="#ABABAB" d="M241.35,82.85c-0.63,0-1.18-0.21-1.63-0.63c-0.45-0.42-0.68-0.92-0.68-1.51c0-0.66,0.21-1.17,0.62-1.56
                      c0.41-0.38,0.98-0.58,1.68-0.58c0.63,0,1.18,0.21,1.63,0.63c0.45,0.42,0.68,0.92,0.68,1.51c0,0.66-0.21,1.17-0.62,1.56
                      C242.62,82.66,242.06,82.85,241.35,82.85z M243,103.49h-3.29V85.87H243V103.49z"/>
                    <path fill="#ABABAB" d="M247.39,94.68c0-1.42,0.24-2.71,0.73-3.85c0.49-1.14,1.15-2.11,1.99-2.9c0.84-0.79,1.84-1.4,3-1.83
                      s2.41-0.64,3.75-0.64c1.32,0,2.55,0.21,3.71,0.64c1.16,0.43,2.16,1.04,3.02,1.83c0.85,0.79,1.52,1.75,2.01,2.88
                      c0.49,1.13,0.73,2.39,0.73,3.79c0,1.4-0.24,2.67-0.71,3.81c-0.48,1.14-1.14,2.12-1.99,2.93c-0.85,0.81-1.86,1.44-3.02,1.88
                      c-1.16,0.44-2.41,0.66-3.75,0.66c-1.37,0-2.63-0.23-3.79-0.68s-2.16-1.08-3-1.9c-0.84-0.81-1.5-1.78-1.98-2.91
                      S247.39,96.03,247.39,94.68z M262.98,94.68c0-0.88-0.15-1.72-0.46-2.51c-0.31-0.79-0.73-1.48-1.28-2.07
                      c-0.55-0.59-1.2-1.06-1.94-1.41c-0.74-0.35-1.56-0.52-2.43-0.52c-1.93,0-3.43,0.59-4.5,1.76c-1.07,1.18-1.61,2.73-1.61,4.68
                      c0,0.88,0.15,1.72,0.46,2.52c0.3,0.8,0.73,1.5,1.28,2.1c0.55,0.6,1.2,1.07,1.94,1.42c0.74,0.35,1.56,0.53,2.43,0.53
                      c0.95,0,1.8-0.16,2.56-0.47c0.76-0.32,1.4-0.76,1.92-1.34c0.52-0.58,0.93-1.26,1.21-2.05C262.84,96.53,262.98,95.65,262.98,94.68z
                      "/>
                    <path fill="#ABABAB" d="M273.92,88.81c0.68-0.95,1.56-1.75,2.62-2.39c1.06-0.64,2.32-0.97,3.79-0.97c2.15,0,3.75,0.6,4.81,1.8
                      c1.06,1.2,1.59,2.78,1.59,4.74v11.49h-3.29V92.34c0-1.29-0.32-2.31-0.95-3.07c-0.63-0.76-1.54-1.14-2.71-1.14
                      c-0.56,0-1.11,0.1-1.65,0.29c-0.54,0.19-1.06,0.46-1.56,0.8c-0.5,0.34-0.96,0.73-1.39,1.19c-0.43,0.45-0.81,0.93-1.15,1.42v11.66
                      h-3.29V85.87h3.18V88.81z"/>
                    <path fill="#ABABAB" d="M292.14,99.52c0.63,0.52,1.43,0.94,2.4,1.25c0.96,0.32,1.99,0.47,3.09,0.47c1.37,0,2.4-0.21,3.09-0.63
                      c0.69-0.42,1.04-0.95,1.04-1.61c0-0.34-0.07-0.66-0.2-0.95c-0.13-0.29-0.38-0.58-0.73-0.85c-0.35-0.27-0.83-0.54-1.45-0.8
                      c-0.61-0.26-1.4-0.55-2.38-0.86c-1.81-0.56-3.15-1.24-4.02-2.02c-0.88-0.78-1.32-1.86-1.32-3.24c0-0.68,0.17-1.31,0.49-1.9
                      s0.81-1.1,1.43-1.54s1.38-0.78,2.27-1.03c0.89-0.25,1.91-0.37,3.06-0.37c1.24,0,2.34,0.12,3.28,0.36c0.94,0.24,1.79,0.54,2.54,0.9
                      l-0.92,2.51c-0.66-0.36-1.41-0.65-2.25-0.88c-0.84-0.23-1.77-0.34-2.8-0.34c-1.27,0-2.23,0.24-2.89,0.71
                      c-0.66,0.47-0.99,0.99-0.99,1.56c0,0.27,0.05,0.52,0.16,0.75c0.11,0.23,0.31,0.44,0.6,0.64c0.29,0.2,0.68,0.41,1.17,0.61
                      c0.49,0.2,1.1,0.42,1.83,0.64c1.2,0.38,2.21,0.77,3.04,1.17c0.83,0.4,1.5,0.83,2.01,1.3c0.51,0.47,0.88,0.99,1.1,1.54
                      c0.22,0.55,0.33,1.17,0.33,1.85c0,0.79-0.18,1.5-0.55,2.13c-0.37,0.63-0.88,1.17-1.54,1.61s-1.46,0.78-2.4,1.02
                      c-0.94,0.24-1.98,0.36-3.13,0.36c-1.46,0-2.77-0.17-3.92-0.51c-1.15-0.34-2.1-0.78-2.85-1.32L292.14,99.52z"/>
                  </g>
                </g>
              </svg>


          <br>
          <a href="/contact" @click="${anchorRoute}" target="_blank">
            <button class="action-btn">Contact me</button>
          </a>  
        </section>

        <section class="to-top">
          <button class="totop-btn" @click="${this.backtoTop}">Back to top</button>
        </section>


      </div> 

    `

    render(template, App.rootEl)
  }
}

export default new HomeView()