import {LitElement, html} from 'lit'
import {anchorRoute} from '../Router'


customElements.define('c-success', class Features extends LitElement {
  constructor(){
    super()    
  } 

  firstUpdated(){
    super.firstUpdated()
  }

  backtoMain(){
    console.log('back to main')
    const mainTab = document.getElementById('main-tab')
    const statusTab = document.getElementById('status-tab')
    mainTab.classList.remove('hidden')
    statusTab.classList.add('hidden')
  }

  render(){    
    return html`
      <style>      
        * { box-sizing: border-box; margin: 0px; padding: 0px; }
        .outer-box { text-align: center; }
        .mi-text { margin: 20px auto;  width: 100%; font-size: 1.5em; 
          font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; }
        .success{font-size: 2em;}
        #tick{ width: 100px; }
        .action-btn {
          width: 200px;
          display: inline-block;
          background-color: var(--action-color);
          color: white;
          transform: skewX(-15deg); 
          border: none;
          font-size: 1.3em;
          padding: .4em;
          transition: all .3s;
          margin: 10px;
        }
        .action-btn:hover { box-shadow: 0px 0px 4px rgba(0,0,0,0.5); }

        @media all and (max-width: 450px){ 
          .action-btn { width: 180px; font-size: 1.1em; padding: .3em; }
          .mi-text {  font-size: 1.2em; width: 90%; }
          .success{font-size: 1.5em;}
          #tick{ width: 70px; }
        }
      </style>

      <div class="outer-box">
        <p class="mi-text success">Success</p>
        <svg id="tick" alt="Success Tick" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.3404 56.3404"><defs><style>.cls-1{fill:#00D1C3;}.cls-2{fill:#fff;}</style></defs><g id="Layer_2" data-name="Layer 2"><g id="Icons"><circle class="cls-1" cx="28.1702" cy="28.1702" r="28.1702"/><path class="cls-2" d="M23.8491,46.9008a3.5027,3.5027,0,0,1-2.5572-1.11L11.1684,34.9545a3.5,3.5,0,0,1,5.1152-4.7793l7.09,7.5889L39.6923,13.0122a3.5,3.5,0,0,1,5.8438,3.8535L26.7714,45.3276a3.4975,3.4975,0,0,1-2.6274,1.5605C24.0459,46.8969,23.9472,46.9008,23.8491,46.9008Z"/></g></g></svg>
        <br><br>
        <p class="mi-text"> Thank you, I will try to respond soon.</p>
        <br><br>
        <a href="/" @click="${anchorRoute}">
          <button class="action-btn">Back Home</button>
        </a>  
       </div>

    `
  }
})
