import fourOFourView from './views/pages/404'
import homeView from './views/pages/1home'
import contactView from './views/pages/2contact'
import workView from './views/pages/4work'

const routes = {
	'404' : fourOFourView,
	'/': homeView,	
	'/contact': contactView,
	'/work': workView,
}

class Router {
	constructor(){
		this.routes = routes
		this.lastRoute = null
	}
	
	init(){
		const newRoute = window.location.pathname
		if (this.lastRoute == newRoute){
			return
		} else {
			this.route(newRoute)
			this.lastRoute = newRoute
		}
		window.addEventListener('popstate', () => {
				this.route(window.location.pathname)
		})
	}
	
	route(fullPathname){
		const pathname = fullPathname.split('?')[0]
		const route = this.routes[pathname]
		if(route){
			this.routes[window.location.pathname].init()
		}else{			
			this.routes['404'].init()			
		}
		const scroll = window.pageYOffset
		if (scroll > 20) {
		  window.scrollTo(0, 0)
		}
	}

	gotoRoute(pathname){
		window.history.pushState({}, pathname, window.location.origin + pathname);
		this.route(pathname)
	}	

}

const AppRouter = new Router()
export default AppRouter

export function gotoRoute(pathname){
	AppRouter.gotoRoute(pathname)
}

export function anchorRoute(e){
	e.preventDefault()	
	const pathname = e.target.closest('a').pathname
	AppRouter.gotoRoute(pathname)
}
