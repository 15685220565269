import App from '../../App'
import {render, html} from 'lit/html.js'
import MailAPI from '../../MailAPI'
import Validator from '../../Validator'

class ContactView {
  init(){    
    console.log('Contact View.init')
    document.title = 'Contact Peta'    
    this.render() 
  }

  async contactSubmitHandler(e){
    e.preventDefault()
    const inputs = document.querySelectorAll('#fname, #userMessage')
    const emailInput = document.getElementById('userEmail')
    let readyToSubmit = true
    const emailStatus = Validator.validateEmail(emailInput)
    if(emailStatus == false){ readyToSubmit = false }
    inputs.forEach(input => {
      if(input.value == "") {
        readyToSubmit = false
        if (input.classList.contains('input-warning')){ return } 
        else { Validator.addWarning(input, '*Required') }
      } else {
        const safeStatus = Validator.safeCheck(input)
        if(safeStatus == false){ readyToSubmit = false }
      }
    }) 
    const mainTab = document.getElementById('main-tab')
    const statusTab = document.getElementById('status-tab')
    if (readyToSubmit == true) {
      mainTab.classList.add('hidden')
      statusTab.innerHTML = '<c-waiting></c-waiting>'
      const form = document.querySelector('form')
      const formData = new FormData(form)
      const formObject = {};
      formData.forEach((value, key) => formObject[key] = value);
      const formJson = JSON.stringify(formObject);
        try{
          const message = await MailAPI.sendContact(formJson)
          statusTab.innerHTML = '<c-success></c-success>'
        }catch(err){
          console.log(err)
          statusTab.innerHTML = '<c-error></c-error>'
        }
    } else { return }
  }



  render(){
    const template = html`   

        <div class="content-panel">  
          <main-app-header pagename="${document.title}"></main-app-header>
          <div class="contact-header h-center"> 

              <svg version="1.1" id="peta-contact-logo" alt="Peta logo main" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" 
                  height="100%" viewBox="0 0 320 113" enable-background="new 0 0 320 113" xml:space="preserve">
                <g id="Layer_1">
                  <g>
                    <g>
                      <path fill="#0BA998" d="M22.54,46.98c0.31,0.32,0.88,0.67,1.56,1c0.68,0.32,1.44,0.61,2.23,0.84c1.59,0.48,3.3,0.78,5.02,0.9
                        c3.43,0.24,6.95-0.25,10.01-1.75c3.07-1.47,5.61-4.04,7.22-7.31c1.64-3.26,2.41-7.07,2.66-10.91c0.03-0.48,0.05-0.96,0.07-1.45
                        c-0.01-0.46-0.04-0.98-0.08-1.44c-0.09-0.94-0.22-1.9-0.43-2.83c-0.41-1.87-1.07-3.68-1.98-5.36c-1.82-3.38-4.67-6.17-8.02-8.08
                        c-3.35-1.92-7.2-3.01-11.09-3.12c-1.94-0.06-3.9,0.16-5.78,0.68c-1.87,0.53-3.67,1.4-5.16,2.68c1.45-1.32,3.24-2.24,5.11-2.82
                        c1.88-0.57,3.86-0.84,5.82-0.83c3.94,0.02,7.88,1.02,11.37,2.9c3.49,1.87,6.52,4.69,8.52,8.17c1,1.73,1.74,3.62,2.22,5.56
                        c0.24,0.98,0.41,1.96,0.53,2.96c0.07,0.52,0.08,0.98,0.14,1.49c0.06,0.49,0.11,0.99,0.16,1.49c0.34,3.96,0.18,8.09-1.12,12.11
                        c-0.65,2-1.6,3.96-2.89,5.73c-1.28,1.78-2.9,3.35-4.73,4.61c-3.68,2.55-8.06,3.84-12.43,4.21c-2.2,0.18-4.41,0.14-6.65-0.18
                        c-1.12-0.16-2.26-0.38-3.42-0.72c-1.15-0.34-2.4-0.8-3.68-1.61L22.54,46.98z"/>
                      <path fill="#43C0B9" d="M39.97,39.71l-4.54-8.57l6.34-4.45c0,0-2.64-2.06-3.52-2.63c-1.75-1.16-3.61-2.16-5.53-3.02
                        c-1.91-0.85-3.9-1.51-5.94-1.99c-2.02-0.48-4.15-0.8-6.14-0.85l0.07-3.38c2.39,0.09,4.62,0.47,6.86,1.01
                        c2.22,0.55,4.4,1.3,6.49,2.25c2.07,0.95,4.07,2.05,5.97,3.33c0.95,0.64,1.87,1.32,2.76,2.05c0.9,0.75,3.78,3.73,3.78,3.73
                        l-7.5,5.1L39.97,39.71z"/>
                      <path fill="#0BA998" d="M20.66,18.23c0.83,3.97,1.45,6.43,1.86,10.51c0.14,2.04,0.19,4.07,0.23,6.1c0.03,1.5,0.04,4.49,0.04,4.49
                        l8.3-4.64l3.51,8.06l5.28-3.17l1.56,3l-6.83,3.02l-1.22,0.54l-0.45-1.15l-2.9-7.33l-9.12,4.8c0,0,0.34-9.63,0.3-13.64
                        C21.24,24.78,21.06,22.26,20.66,18.23z"/>
                      <path fill="#43C0B9" d="M12.2,9.03c1.9,4.41,2.9,9.21,3.51,14.02c0.59,4.82,0.76,9.69,0.67,14.56
                        c-0.21,9.74-1.42,19.46-3.53,29.1l-8.09-2.41c3.3-8.69,5.84-17.79,7.42-27.07c0.78-4.64,1.31-9.34,1.42-14.06
                        C13.7,18.43,13.43,13.66,12.2,9.03z"/>
                    </g>
                    <g>
                      <path fill="#43C0B9" d="M110.58,38.78c-0.77,0-1.47,0.06-2.1,0.18c-0.17-0.67-0.38-1.13-0.63-1.39s-0.74-0.39-1.47-0.39
                        c-2.3,0-4.47,0.66-6.52,1.98c-2.05,1.32-3.69,3.06-4.92,5.22c-1.16,2.04-1.77,4.21-1.84,6.48c-0.71,0.65-1.37,1.2-1.96,1.63
                        c-1.1,0.79-2.37,1.18-3.8,1.18c-1.77,0-3.06-0.64-3.87-1.91c-0.82-1.27-1.22-3.48-1.22-6.63c0-0.85,0.03-2.03,0.1-3.54h6.1
                        c3.76,0,5.65-1.04,5.65-3.13c0-0.58-0.12-0.97-0.35-1.18c-0.23-0.21-0.62-0.32-1.15-0.32h-5.6c4.33-3.48,6.5-7.57,6.5-12.26
                        c0-1.7-0.4-3.1-1.2-4.22c-0.8-1.12-1.9-1.68-3.3-1.68c-1.9,0-3.67,0.82-5.32,2.45c-1.65,1.64-3.06,3.83-4.22,6.59
                        c-1.17,2.76-2.03,5.8-2.6,9.13H74c-1.5,0-2.25,0.85-2.25,2.54c0,0.73,0.17,1.26,0.53,1.59c0.35,0.33,1.02,0.5,2.02,0.5h1.95
                        c-0.1,1.36-0.15,2.71-0.15,4.04c0,0.98,0.05,1.9,0.14,2.77c-0.3,0.1-0.58,0.27-0.83,0.54c-1.4,1.54-3.06,2.79-4.97,3.75
                        c-1.92,0.95-3.62,1.43-5.12,1.43c-2.33,0-3.92-0.7-4.75-2.09c3.93-0.88,6.72-2.07,8.37-3.57c1.65-1.5,2.47-3.29,2.47-5.38
                        c0-1.79-0.58-3.2-1.75-4.25c-1.17-1.04-2.72-1.57-4.65-1.57c-2.17,0-4.11,0.53-5.82,1.59c-1.72,1.06-3.06,2.49-4.02,4.29
                        c-0.97,1.8-1.45,3.75-1.45,5.84c0,3,0.97,5.36,2.9,7.09c1.93,1.73,4.65,2.59,8.15,2.59c2.56,0,4.97-0.57,7.22-1.73
                        c2.25-1.15,3.99-2.41,5.22-3.77c0.04-0.04,0.07-0.1,0.11-0.14c1.53,3.75,4.52,5.64,8.99,5.64c2.2,0,4.15-0.51,5.85-1.54
                        c0.71-0.43,1.39-0.92,2.05-1.45c0.2,0.33,0.42,0.66,0.67,0.95c1.18,1.36,2.72,2.04,4.62,2.04c2.53,0,4.31-1.3,5.35-3.91
                        l-0.05,0.73c0,1.12,0.27,1.93,0.82,2.43s1.39,0.75,2.52,0.75c1.17,0,2.01-0.25,2.52-0.75s0.77-1.32,0.77-2.48
                        c0-2.09,0.1-3.97,0.3-5.66c0.2-1.68,0.48-3.44,0.85-5.29c0.33-1.73,0.5-2.94,0.5-3.63c0-0.67-0.22-1.18-0.65-1.54
                        C111.99,38.97,111.38,38.78,110.58,38.78z M84.21,29.59c0.55-2.01,1.13-3.59,1.75-4.72c0.62-1.14,1.17-1.7,1.67-1.7
                        c0.73,0,1.1,0.89,1.1,2.68c0,1.57-0.49,3.32-1.47,5.25c-0.98,1.92-2.46,3.66-4.42,5.2C83.21,33.83,83.67,31.6,84.21,29.59z
                        M59.75,48.41c0-1.85,0.42-3.42,1.27-4.72c0.85-1.3,1.89-1.95,3.12-1.95c0.6,0,1.07,0.17,1.42,0.52
                        c0.35,0.35,0.52,0.81,0.52,1.39c0,1.12-0.57,2.1-1.7,2.95c-1.13,0.85-2.68,1.48-4.65,1.91V48.41z M106.03,46.01
                        c-0.6,3.03-1.28,5.13-2.05,6.31s-1.62,1.77-2.55,1.77c-0.63,0-1.14-0.23-1.52-0.7c-0.38-0.47-0.57-1.16-0.57-2.07
                        c0-1.7,0.35-3.3,1.05-4.81c0.7-1.51,1.63-2.73,2.8-3.66c1.17-0.92,2.43-1.4,3.8-1.43L106.03,46.01z"/>
                      <path fill="#43C0B9" d="M166.83,23.84c-1.22-1.33-2.84-2.32-4.87-2.98c-2.03-0.65-4.27-0.98-6.7-0.98
                        c-3.27,0-6.11,0.47-8.52,1.41c-2.42,0.94-4.26,2.26-5.55,3.95c-1.28,1.7-1.92,3.63-1.92,5.81c0,2.09,0.58,3.84,1.72,5.25
                        s2.53,2.52,4.15,3.34c1.62,0.82,3.71,1.68,6.27,2.59c2,0.73,3.55,1.34,4.65,1.84c1.1,0.5,2.02,1.12,2.75,1.86
                        c0.73,0.74,1.1,1.63,1.1,2.66c0,1.67-0.8,2.96-2.4,3.88c-1.6,0.92-3.82,1.39-6.65,1.39c-2.73,0-4.86-0.33-6.37-1
                        c-1.52-0.67-2.27-1.68-2.27-3.04c0-0.33,0.02-0.65,0.07-0.95c0.05-0.3,0.08-0.51,0.08-0.64c0.1-0.45,0.15-0.98,0.15-1.59
                        c0-0.67-0.28-1.21-0.85-1.64c-0.57-0.42-1.3-0.64-2.2-0.64c-1.23,0-2.17,0.44-2.8,1.32c-0.63,0.88-0.95,2.15-0.95,3.82
                        c0,3.15,1.33,5.49,4,7.02c2.67,1.53,6.21,2.29,10.64,2.29c5,0,8.99-0.96,11.97-2.88c2.98-1.92,4.47-4.67,4.47-8.24
                        c0-1.91-0.54-3.51-1.63-4.81c-1.08-1.3-2.39-2.33-3.92-3.09c-1.53-0.76-3.57-1.6-6.1-2.54c-2.1-0.76-3.73-1.41-4.9-1.95
                        c-1.17-0.55-2.14-1.23-2.92-2.04c-0.78-0.82-1.17-1.8-1.17-2.95c0-1.73,0.76-3.07,2.27-4.02c1.52-0.95,3.71-1.43,6.57-1.43
                        c2.03,0,3.73,0.33,5.1,1c1.37,0.67,2.05,1.64,2.05,2.91c0,0.24-0.05,0.82-0.15,1.73c-0.1,0.48-0.15,1.01-0.15,1.59
                        c0,0.67,0.29,1.24,0.88,1.73c0.58,0.48,1.31,0.73,2.17,0.73c1.23,0,2.17-0.48,2.8-1.45c0.63-0.97,0.95-2.41,0.95-4.31
                        C168.66,26.81,168.05,25.17,166.83,23.84z"/>
                      <path fill="#43C0B9" d="M288.75,43.51h-0.45c0.37-1.6,0.55-2.69,0.55-3.27c0-0.97-0.26-1.7-0.77-2.2s-1.26-0.75-2.22-0.75
                        c-1.07,0-2.25,0.35-3.55,1.04c-1.3,0.7-2.63,1.86-4,3.5s-2.6,3.76-3.7,6.36c0.13-1.33,0.3-2.45,0.5-3.36
                        c0.2-0.91,0.47-1.97,0.8-3.18c0.5-1.7,0.75-2.79,0.75-3.27c0-0.42-0.13-0.71-0.4-0.86c-0.27-0.15-0.73-0.23-1.4-0.23
                        c-1.23,0-2.19,0.2-2.87,0.61c-0.68,0.41-1.17,1.05-1.47,1.93c-1.15,3.29-1.75,6.76-1.83,10.39c-0.99,1.03-1.81,1.82-2.47,2.35
                        c-0.9,0.74-1.67,1.11-2.3,1.11c-0.4,0-0.72-0.14-0.97-0.41s-0.37-0.64-0.37-1.09c0-1.36,0.38-3.79,1.15-7.27
                        c0.5-2.39,0.75-3.91,0.75-4.54c0-1.06-0.82-1.59-2.45-1.59c-0.77,0-1.5,0.06-2.2,0.18c-0.17-0.67-0.38-1.13-0.63-1.39
                        s-0.74-0.39-1.47-0.39c-2.3,0-4.47,0.66-6.52,1.98c-2.05,1.32-3.69,3.06-4.92,5.22c-0.09,0.16-0.17,0.32-0.25,0.48
                        c-2.1,0.39-3.78,0.62-5.04,0.68c0.8-1.76,1.2-3.32,1.2-4.68c0-1.18-0.3-2.08-0.9-2.7c-0.6-0.62-1.38-0.93-2.35-0.93
                        c-1.1,0-2.08,0.3-2.95,0.91c-0.87,0.61-1.55,1.39-2.05,2.36c-0.5,0.97-0.75,1.97-0.75,3c0,1.21,0.25,2.26,0.75,3.13
                        c-1,1.61-2.5,3.76-4.5,6.45c-0.47-5.57-0.7-10.1-0.7-13.58c0-0.85-0.18-1.44-0.53-1.77c-0.35-0.33-0.97-0.5-1.87-0.5
                        c-0.73,0-1.36,0.16-1.87,0.48c-0.52,0.32-0.96,0.93-1.32,1.84c-1.9,4.69-3.98,9.21-6.25,13.54c-0.47-5.57-0.7-10.1-0.7-13.58
                        c0-0.82-0.21-1.4-0.63-1.75c-0.42-0.35-1.06-0.52-1.92-0.52c-1.13,0-1.98,0.2-2.55,0.59c-0.57,0.39-0.87,1.09-0.9,2.09v1.59
                        c0,2.39,0.11,5.08,0.34,8.06c-1.27,1.26-2.71,2.31-4.36,3.13c-1.92,0.95-3.62,1.43-5.12,1.43c-2.33,0-3.92-0.7-4.75-2.09
                        c3.93-0.88,6.72-2.07,8.37-3.57c1.65-1.5,2.47-3.29,2.47-5.38c0-1.79-0.58-3.2-1.75-4.25c-1.17-1.04-2.72-1.57-4.65-1.57
                        c-2.17,0-4.11,0.53-5.82,1.59c-1.72,1.06-3.06,2.49-4.02,4.29c-0.97,1.8-1.45,3.75-1.45,5.84c0,0.64,0.05,1.25,0.14,1.84
                        c-0.72,0.67-1.39,1.23-1.99,1.66c-1.1,0.79-2.37,1.18-3.8,1.18c-1.77,0-3.06-0.64-3.87-1.91c-0.82-1.27-1.22-3.48-1.22-6.63
                        c0-0.85,0.03-2.03,0.1-3.54h6.1c3.76,0,5.65-1.04,5.65-3.13c0-0.58-0.12-0.97-0.35-1.18c-0.23-0.21-0.62-0.32-1.15-0.32h-5.6
                        c4.33-3.48,6.5-7.57,6.5-12.26c0-1.7-0.4-3.1-1.2-4.22c-0.8-1.12-1.9-1.68-3.3-1.68c-1.9,0-3.67,0.82-5.32,2.45
                        c-1.65,1.64-3.06,3.83-4.22,6.59c-1.17,2.76-2.03,5.8-2.6,9.13h-2.85c-1.5,0-2.25,0.85-2.25,2.54c0,0.73,0.17,1.26,0.53,1.59
                        c0.35,0.33,1.02,0.5,2.02,0.5h1.95c-0.1,1.36-0.15,2.71-0.15,4.04c0,8.69,3.4,13.04,10.2,13.04c2.2,0,4.15-0.51,5.85-1.54
                        c0.98-0.59,1.9-1.29,2.79-2.07c0.29,0.36,0.6,0.71,0.96,1.02c1.93,1.73,4.65,2.59,8.15,2.59c2.57,0,4.97-0.57,7.22-1.73
                        c1.34-0.68,2.47-1.41,3.45-2.17c0.26,1.02,0.62,1.85,1.1,2.44c0.78,0.97,1.86,1.45,3.22,1.45c1.1,0,2.06-0.26,2.87-0.77
                        c0.82-0.51,1.63-1.44,2.45-2.77c0.82-1.33,1.74-3.27,2.77-5.81l0.25,3.32c0.17,2.15,0.59,3.7,1.27,4.63
                        c0.68,0.94,1.69,1.41,3.02,1.41c1.2,0,2.27-0.32,3.22-0.95c0.95-0.64,2.09-1.82,3.42-3.54c1.17-1.51,2.23-2.98,3.2-4.41
                        c1.3,0.36,2.68,0.54,4.15,0.54c0.8,0,1.59-0.05,2.35-0.15c-0.03,0.37-0.05,0.73-0.05,1.11c0,2.21,0.62,4,1.85,5.36
                        c1.23,1.36,2.88,2.04,4.95,2.04c2.63,0,4.81-1.27,6.55-3.82c0.47,1.18,1.16,2.11,2.07,2.79c0.92,0.68,1.92,1.02,3.02,1.02
                        c1.43,0,2.92-0.53,4.45-1.59c0.6-0.42,1.19-0.88,1.76-1.37c0.1,0.43,0.22,0.82,0.36,1.15c0.52,1.21,1.41,1.82,2.67,1.82
                        c1.07,0,1.83-0.24,2.3-0.73c0.47-0.48,0.88-1.45,1.25-2.91c0.5-1.85,1.15-3.6,1.95-5.27c0.8-1.67,1.6-3.01,2.4-4.02
                        c0.8-1.01,1.43-1.52,1.9-1.52c0.17,0,0.31,0.06,0.42,0.18c0.12,0.12,0.17,0.3,0.17,0.55c0,0.18-0.03,0.52-0.1,1
                        c-0.07,0.67-0.1,1.29-0.1,1.86c0,1.03,0.33,1.85,0.97,2.45c0.65,0.61,1.44,0.91,2.37,0.91c1.17,0,2.23-0.38,3.2-1.14
                        c0.97-0.76,1.72-1.7,2.25-2.82c0.2-0.45,0.3-0.88,0.3-1.27c0-0.7-0.26-1.28-0.77-1.75C290.1,43.74,289.48,43.51,288.75,43.51z
                        M180.67,29.59c0.55-2.01,1.13-3.59,1.75-4.72c0.62-1.14,1.17-1.7,1.67-1.7c0.73,0,1.1,0.89,1.1,2.68c0,1.57-0.49,3.32-1.47,5.25
                        c-0.98,1.92-2.46,3.66-4.42,5.2C179.67,33.83,180.13,31.6,180.67,29.59z M195.49,48.41c0-1.85,0.42-3.42,1.27-4.72
                        c0.85-1.3,1.89-1.95,3.12-1.95c0.6,0,1.08,0.17,1.42,0.52c0.35,0.35,0.52,0.81,0.52,1.39c0,1.12-0.57,2.1-1.7,2.95
                        c-1.13,0.85-2.68,1.48-4.65,1.91V48.41z M256.56,49.78c-0.3,1.3-0.78,2.35-1.45,3.13c-0.67,0.79-1.37,1.18-2.1,1.18
                        c-0.73,0-1.31-0.23-1.72-0.68c-0.42-0.45-0.63-1.15-0.63-2.09c0-1.7,0.35-3.3,1.05-4.81c0.7-1.51,1.64-2.73,2.82-3.66
                        c1.18-0.92,2.47-1.4,3.87-1.43L256.56,49.78z"/>
                      <path fill="#43C0B9" d="M307.64,41.6c3.76,0,5.65-1.04,5.65-3.13c0-0.58-0.12-0.97-0.35-1.18c-0.23-0.21-0.62-0.32-1.15-0.32
                        h-5.6c4.33-3.48,6.5-7.57,6.5-12.26c0-1.7-0.4-3.1-1.2-4.22c-0.8-1.12-1.9-1.68-3.3-1.68c-1.9,0-3.67,0.82-5.32,2.45
                        c-1.65,1.64-3.06,3.83-4.22,6.59c-1.17,2.76-2.03,5.8-2.6,9.13h-2.85c-1.5,0-2.25,0.85-2.25,2.54c0,0.73,0.17,1.26,0.53,1.59
                        c0.35,0.33,1.02,0.5,2.02,0.5h1.95c-0.1,1.36-0.15,2.71-0.15,4.04c0,3.88,0.73,7.02,2.2,9.43c1.47,2.41,3.78,3.61,6.95,3.61
                        c2.2,0,4.17-0.65,5.9-1.95c0.5-0.36,0.88-0.82,1.12-1.36c0.25-0.54,0.38-1.1,0.38-1.68c0-0.57-0.13-1.07-0.38-1.48
                        c-0.25-0.41-0.57-0.61-0.97-0.61c-0.37,0-0.73,0.15-1.1,0.45c-0.6,0.48-1.27,0.88-2,1.18c-0.73,0.3-1.37,0.45-1.9,0.45
                        c-1.03,0-1.84-0.26-2.42-0.79c-0.58-0.53-1-1.41-1.25-2.63c-0.25-1.23-0.38-2.93-0.38-5.11c0-0.85,0.03-2.03,0.1-3.54H307.64z
                        M303.41,29.59c0.55-2.01,1.13-3.59,1.75-4.72c0.62-1.14,1.17-1.7,1.67-1.7c0.73,0,1.1,0.89,1.1,2.68c0,1.57-0.49,3.32-1.47,5.25
                        c-0.98,1.92-2.46,3.66-4.42,5.2C302.4,33.83,302.86,31.6,303.41,29.59z"/>
                    </g>
                    <path fill="#43C0B9" d="M21,66.59c23-0.57,47.18-0.72,70.81-0.9c23.63-0.13,47.03,0.09,70.65,0.2L304,66.59v0.2l-141.58,0.7
                      c-23.63,0.11-47.08,0.33-70.71,0.2C68.08,67.5,44,67.36,21,66.79V66.59z"/>
                  </g>
                </g>
                <g id="side_details">
                  <g>
                    <path fill="#ABABAB" d="M24.01,79.83c1.15-0.11,2.27-0.2,3.37-0.25c1.1-0.06,2.35-0.08,3.77-0.08c2.2,0,4.09,0.32,5.67,0.95
                      c1.59,0.63,2.9,1.5,3.95,2.61c1.05,1.11,1.82,2.39,2.32,3.86c0.5,1.47,0.75,3.03,0.75,4.68c0,1.65-0.26,3.21-0.77,4.69
                      c-0.51,1.48-1.3,2.77-2.36,3.88c-1.06,1.11-2.42,1.98-4.06,2.63c-1.65,0.64-3.62,0.97-5.91,0.97c-1.39,0-2.6-0.03-3.64-0.08
                      c-1.04-0.06-2.07-0.14-3.09-0.25V79.83z M31.03,82.27c-0.71,0-1.35,0.02-1.94,0.05s-1.12,0.07-1.61,0.12v18.33
                      c0.51,0.07,1.05,0.11,1.61,0.14c0.56,0.02,1.15,0.03,1.76,0.03c1.63,0,3.05-0.23,4.24-0.69c1.19-0.46,2.18-1.11,2.95-1.93
                      c0.77-0.82,1.34-1.81,1.7-2.95s0.55-2.4,0.55-3.78c0-1.22-0.18-2.39-0.55-3.51s-0.93-2.11-1.68-2.98
                      c-0.76-0.87-1.72-1.56-2.89-2.07S32.62,82.27,31.03,82.27z"/>
                    <path fill="#ABABAB" d="M50.24,82.85c-0.63,0-1.18-0.21-1.63-0.63c-0.45-0.42-0.68-0.92-0.68-1.51c0-0.66,0.21-1.17,0.62-1.56
                      c0.41-0.38,0.98-0.58,1.68-0.58c0.63,0,1.18,0.21,1.63,0.63c0.45,0.42,0.68,0.92,0.68,1.51c0,0.66-0.21,1.17-0.62,1.56
                      C51.51,82.66,50.95,82.85,50.24,82.85z M51.89,103.49h-3.29V85.87h3.29V103.49z"/>
                    <path fill="#ABABAB" d="M65.28,85.46c1.1,0,2.08,0.17,2.95,0.51c0.87,0.34,1.62,0.76,2.25,1.25v-1.36h3v17.21
                      c0,1.47-0.21,2.72-0.62,3.76c-0.42,1.04-1.01,1.89-1.77,2.56c-0.77,0.67-1.69,1.16-2.76,1.47c-1.07,0.32-2.27,0.47-3.59,0.47
                      c-1.15,0-2.24-0.12-3.29-0.37c-1.05-0.25-1.99-0.62-2.82-1.12l1.21-2.64c0.59,0.43,1.27,0.78,2.05,1.07
                      c0.78,0.28,1.73,0.42,2.85,0.42c1.73,0,3.09-0.42,4.08-1.25c0.99-0.84,1.48-2.19,1.48-4.07v-2.17c-0.49,0.54-1.19,1.06-2.1,1.54
                      c-0.91,0.49-2.01,0.73-3.27,0.73c-1.07,0-2.12-0.2-3.15-0.59c-1.02-0.4-1.93-0.97-2.71-1.73c-0.78-0.76-1.41-1.68-1.88-2.78
                      c-0.48-1.1-0.71-2.35-0.71-3.78c0-1.31,0.23-2.52,0.7-3.64c0.46-1.12,1.09-2.08,1.88-2.9c0.79-0.81,1.73-1.45,2.8-1.91
                      C62.91,85.69,64.06,85.46,65.28,85.46z M65.65,100.78c0.98,0,1.85-0.2,2.63-0.61s1.41-0.88,1.9-1.42v-9.22
                      c-0.56-0.36-1.21-0.69-1.94-0.98c-0.73-0.29-1.61-0.44-2.63-0.44c-0.76,0-1.48,0.14-2.18,0.42c-0.7,0.28-1.31,0.69-1.85,1.24
                      c-0.54,0.54-0.96,1.2-1.28,1.98c-0.32,0.78-0.48,1.68-0.48,2.69c0,0.97,0.16,1.85,0.48,2.63c0.32,0.78,0.74,1.45,1.28,2
                      c0.54,0.55,1.15,0.98,1.85,1.27C64.13,100.63,64.87,100.78,65.65,100.78z"/>
                    <path fill="#ABABAB" d="M80.79,82.85c-0.63,0-1.18-0.21-1.63-0.63c-0.45-0.42-0.68-0.92-0.68-1.51c0-0.66,0.21-1.17,0.62-1.56
                      c0.41-0.38,0.98-0.58,1.68-0.58c0.63,0,1.18,0.21,1.63,0.63c0.45,0.42,0.68,0.92,0.68,1.51c0,0.66-0.21,1.17-0.62,1.56
                      C82.06,82.66,81.5,82.85,80.79,82.85z M82.44,103.49h-3.29V85.87h3.29V103.49z"/>
                    <path fill="#ABABAB" d="M89.8,80.95h2.52v5.56h5.27v2.64h-5.27v8.51c0,0.66,0.08,1.21,0.24,1.66c0.16,0.45,0.37,0.81,0.64,1.08
                      c0.27,0.27,0.59,0.47,0.97,0.59c0.38,0.12,0.77,0.19,1.19,0.19c0.61,0,1.18-0.09,1.72-0.27c0.54-0.18,1.05-0.4,1.54-0.64
                      l0.91,2.44c-0.49,0.27-1.15,0.54-1.98,0.8c-0.83,0.26-1.7,0.39-2.6,0.39c-1.83,0-3.27-0.55-4.34-1.66
                      c-1.06-1.11-1.59-2.71-1.59-4.81v-8.27h-3.29v-2.64h3.37L89.8,80.95z"/>
                    <path fill="#ABABAB" d="M100.81,94.78c0-1.31,0.22-2.54,0.66-3.68s1.05-2.13,1.85-2.97c0.79-0.84,1.74-1.49,2.85-1.97
                      c1.11-0.47,2.32-0.71,3.64-0.71c1.1,0,2.09,0.18,2.98,0.53c0.89,0.35,1.66,0.79,2.32,1.3v-1.42h3v13.35
                      c0,0.86,0.23,1.42,0.68,1.68c0.45,0.26,0.95,0.39,1.48,0.39l-0.69,2.37c-2.34,0-3.76-0.88-4.24-2.64
                      c-0.29,0.36-0.63,0.72-1.01,1.07c-0.38,0.35-0.81,0.66-1.3,0.93c-0.49,0.27-1.04,0.49-1.65,0.64c-0.61,0.16-1.28,0.24-2.01,0.24
                      c-1.2,0-2.31-0.21-3.35-0.64c-1.04-0.43-1.94-1.04-2.71-1.83c-0.77-0.79-1.38-1.75-1.83-2.86
                      C101.04,97.44,100.81,96.18,100.81,94.78z M110.1,101.18c0.93,0,1.8-0.2,2.63-0.61c0.83-0.41,1.52-0.94,2.09-1.59v-9.45
                      c-0.66-0.43-1.38-0.77-2.18-1.03c-0.79-0.26-1.63-0.39-2.51-0.39c-0.88,0-1.68,0.16-2.42,0.47c-0.73,0.32-1.36,0.76-1.88,1.34
                      c-0.52,0.58-0.93,1.27-1.23,2.08c-0.29,0.81-0.44,1.71-0.44,2.68c0,0.95,0.15,1.82,0.44,2.63c0.29,0.8,0.71,1.49,1.24,2.07
                      c0.54,0.58,1.16,1.02,1.88,1.34C108.46,101.02,109.25,101.18,110.1,101.18z"/>
                    <path fill="#ABABAB" d="M127.78,97.66c0,1.22,0.27,2.12,0.82,2.71s1.31,0.88,2.29,0.88c0.46,0,0.91-0.05,1.35-0.15
                      c0.44-0.1,0.83-0.22,1.17-0.36l0.81,2.47c-0.44,0.18-0.98,0.34-1.63,0.47c-0.65,0.14-1.36,0.2-2.14,0.2
                      c-0.85,0-1.64-0.11-2.36-0.34c-0.72-0.23-1.35-0.58-1.88-1.05c-0.54-0.47-0.96-1.08-1.26-1.83c-0.31-0.75-0.46-1.65-0.46-2.71
                      V78.41h3.29V97.66z"/>
                    <path fill="#ABABAB" d="M148.23,98.78c0.88,0.68,1.87,1.23,2.96,1.66c1.1,0.43,2.38,0.64,3.84,0.64c1.76,0,3.15-0.34,4.17-1.02
                      c1.02-0.68,1.54-1.59,1.54-2.74c0-0.47-0.07-0.92-0.22-1.32c-0.15-0.41-0.43-0.8-0.86-1.17c-0.43-0.37-1.02-0.75-1.79-1.12
                      c-0.77-0.37-1.76-0.77-2.98-1.2c-1.32-0.47-2.43-0.94-3.35-1.41c-0.91-0.46-1.65-0.97-2.21-1.52c-0.56-0.55-0.96-1.16-1.21-1.83
                      c-0.24-0.67-0.37-1.43-0.37-2.29c0-0.84,0.19-1.63,0.57-2.37c0.38-0.75,0.94-1.39,1.68-1.95c0.74-0.55,1.66-0.99,2.74-1.3
                      c1.08-0.32,2.32-0.47,3.71-0.47c1.51,0,2.87,0.14,4.06,0.42c1.19,0.28,2.38,0.68,3.55,1.2l-1.28,2.74
                      c-0.83-0.45-1.8-0.83-2.91-1.14c-1.11-0.3-2.27-0.46-3.49-0.46c-1.61,0-2.88,0.29-3.81,0.86c-0.93,0.58-1.39,1.34-1.39,2.29
                      c0,0.45,0.07,0.85,0.2,1.2c0.13,0.35,0.4,0.69,0.81,1.02s0.96,0.66,1.68,1c0.72,0.34,1.68,0.71,2.87,1.12
                      c1.41,0.5,2.61,1.01,3.59,1.52c0.98,0.52,1.76,1.07,2.34,1.66c0.59,0.59,1.01,1.22,1.26,1.9c0.26,0.68,0.38,1.42,0.38,2.24
                      c0,1.11-0.23,2.09-0.7,2.95c-0.46,0.86-1.1,1.59-1.92,2.19c-0.82,0.6-1.8,1.05-2.95,1.36c-1.15,0.3-2.39,0.46-3.73,0.46
                      c-1.78,0-3.4-0.22-4.87-0.66c-1.46-0.44-2.7-1.02-3.7-1.75L148.23,98.78z"/>
                    <path fill="#ABABAB" d="M167.62,94.68c0-1.42,0.24-2.71,0.73-3.85c0.49-1.14,1.15-2.11,1.99-2.9c0.84-0.79,1.84-1.4,3-1.83
                      s2.41-0.64,3.75-0.64c1.32,0,2.55,0.21,3.71,0.64c1.16,0.43,2.16,1.04,3.02,1.83c0.85,0.79,1.52,1.75,2.01,2.88
                      c0.49,1.13,0.73,2.39,0.73,3.79c0,1.4-0.24,2.67-0.71,3.81c-0.48,1.14-1.14,2.12-1.99,2.93c-0.85,0.81-1.86,1.44-3.02,1.88
                      c-1.16,0.44-2.41,0.66-3.75,0.66c-1.37,0-2.63-0.23-3.79-0.68s-2.16-1.08-3-1.9c-0.84-0.81-1.5-1.78-1.98-2.91
                      S167.62,96.03,167.62,94.68z M183.21,94.68c0-0.88-0.15-1.72-0.46-2.51c-0.31-0.79-0.73-1.48-1.28-2.07
                      c-0.55-0.59-1.2-1.06-1.94-1.41c-0.74-0.35-1.56-0.52-2.43-0.52c-1.93,0-3.43,0.59-4.5,1.76c-1.07,1.18-1.61,2.73-1.61,4.68
                      c0,0.88,0.15,1.72,0.46,2.52c0.3,0.8,0.73,1.5,1.28,2.1c0.55,0.6,1.2,1.07,1.94,1.42c0.74,0.35,1.56,0.53,2.43,0.53
                      c0.95,0,1.8-0.16,2.56-0.47c0.76-0.32,1.4-0.76,1.92-1.34c0.52-0.58,0.93-1.26,1.21-2.05C183.07,96.53,183.21,95.65,183.21,94.68z
                      "/>
                    <path fill="#ABABAB" d="M194.08,97.66c0,1.22,0.27,2.12,0.82,2.71s1.31,0.88,2.29,0.88c0.46,0,0.91-0.05,1.35-0.15
                      c0.44-0.1,0.83-0.22,1.17-0.36l0.81,2.47c-0.44,0.18-0.98,0.34-1.63,0.47c-0.65,0.14-1.36,0.2-2.14,0.2
                      c-0.85,0-1.64-0.11-2.36-0.34c-0.72-0.23-1.35-0.58-1.88-1.05c-0.54-0.47-0.96-1.08-1.26-1.83c-0.31-0.75-0.46-1.65-0.46-2.71
                      V78.41h3.29V97.66z"/>
                    <path fill="#ABABAB" d="M220.09,103.66c-1.17,0-2.1-0.21-2.78-0.64c-0.68-0.43-1.16-1.05-1.43-1.86
                      c-0.85,0.81-1.84,1.47-2.95,1.98c-1.11,0.51-2.32,0.76-3.64,0.76c-0.83,0-1.63-0.12-2.4-0.36s-1.45-0.62-2.05-1.14
                      c-0.6-0.52-1.07-1.2-1.43-2.03c-0.35-0.84-0.53-1.85-0.53-3.05V85.87h3.29v11.05c0,1.38,0.31,2.43,0.93,3.17
                      c0.62,0.73,1.56,1.1,2.8,1.1c1.02,0,2-0.19,2.93-0.56c0.93-0.37,1.76-0.88,2.49-1.51V85.87h3.29v13.05c0,0.5,0.06,0.9,0.18,1.2
                      c0.12,0.31,0.28,0.54,0.48,0.71c0.19,0.17,0.43,0.29,0.7,0.36c0.27,0.07,0.54,0.1,0.8,0.1L220.09,103.66z"/>
                    <path fill="#ABABAB" d="M226.68,80.95h2.52v5.56h5.27v2.64h-5.27v8.51c0,0.66,0.08,1.21,0.24,1.66c0.16,0.45,0.37,0.81,0.64,1.08
                      c0.27,0.27,0.59,0.47,0.97,0.59c0.38,0.12,0.77,0.19,1.19,0.19c0.61,0,1.18-0.09,1.72-0.27c0.54-0.18,1.05-0.4,1.54-0.64
                      l0.91,2.44c-0.49,0.27-1.15,0.54-1.98,0.8c-0.83,0.26-1.7,0.39-2.6,0.39c-1.83,0-3.27-0.55-4.34-1.66
                      c-1.06-1.11-1.59-2.71-1.59-4.81v-8.27h-3.29v-2.64h3.37L226.68,80.95z"/>
                    <path fill="#ABABAB" d="M241.35,82.85c-0.63,0-1.18-0.21-1.63-0.63c-0.45-0.42-0.68-0.92-0.68-1.51c0-0.66,0.21-1.17,0.62-1.56
                      c0.41-0.38,0.98-0.58,1.68-0.58c0.63,0,1.18,0.21,1.63,0.63c0.45,0.42,0.68,0.92,0.68,1.51c0,0.66-0.21,1.17-0.62,1.56
                      C242.62,82.66,242.06,82.85,241.35,82.85z M243,103.49h-3.29V85.87H243V103.49z"/>
                    <path fill="#ABABAB" d="M247.39,94.68c0-1.42,0.24-2.71,0.73-3.85c0.49-1.14,1.15-2.11,1.99-2.9c0.84-0.79,1.84-1.4,3-1.83
                      s2.41-0.64,3.75-0.64c1.32,0,2.55,0.21,3.71,0.64c1.16,0.43,2.16,1.04,3.02,1.83c0.85,0.79,1.52,1.75,2.01,2.88
                      c0.49,1.13,0.73,2.39,0.73,3.79c0,1.4-0.24,2.67-0.71,3.81c-0.48,1.14-1.14,2.12-1.99,2.93c-0.85,0.81-1.86,1.44-3.02,1.88
                      c-1.16,0.44-2.41,0.66-3.75,0.66c-1.37,0-2.63-0.23-3.79-0.68s-2.16-1.08-3-1.9c-0.84-0.81-1.5-1.78-1.98-2.91
                      S247.39,96.03,247.39,94.68z M262.98,94.68c0-0.88-0.15-1.72-0.46-2.51c-0.31-0.79-0.73-1.48-1.28-2.07
                      c-0.55-0.59-1.2-1.06-1.94-1.41c-0.74-0.35-1.56-0.52-2.43-0.52c-1.93,0-3.43,0.59-4.5,1.76c-1.07,1.18-1.61,2.73-1.61,4.68
                      c0,0.88,0.15,1.72,0.46,2.52c0.3,0.8,0.73,1.5,1.28,2.1c0.55,0.6,1.2,1.07,1.94,1.42c0.74,0.35,1.56,0.53,2.43,0.53
                      c0.95,0,1.8-0.16,2.56-0.47c0.76-0.32,1.4-0.76,1.92-1.34c0.52-0.58,0.93-1.26,1.21-2.05C262.84,96.53,262.98,95.65,262.98,94.68z
                      "/>
                    <path fill="#ABABAB" d="M273.92,88.81c0.68-0.95,1.56-1.75,2.62-2.39c1.06-0.64,2.32-0.97,3.79-0.97c2.15,0,3.75,0.6,4.81,1.8
                      c1.06,1.2,1.59,2.78,1.59,4.74v11.49h-3.29V92.34c0-1.29-0.32-2.31-0.95-3.07c-0.63-0.76-1.54-1.14-2.71-1.14
                      c-0.56,0-1.11,0.1-1.65,0.29c-0.54,0.19-1.06,0.46-1.56,0.8c-0.5,0.34-0.96,0.73-1.39,1.19c-0.43,0.45-0.81,0.93-1.15,1.42v11.66
                      h-3.29V85.87h3.18V88.81z"/>
                    <path fill="#ABABAB" d="M292.14,99.52c0.63,0.52,1.43,0.94,2.4,1.25c0.96,0.32,1.99,0.47,3.09,0.47c1.37,0,2.4-0.21,3.09-0.63
                      c0.69-0.42,1.04-0.95,1.04-1.61c0-0.34-0.07-0.66-0.2-0.95c-0.13-0.29-0.38-0.58-0.73-0.85c-0.35-0.27-0.83-0.54-1.45-0.8
                      c-0.61-0.26-1.4-0.55-2.38-0.86c-1.81-0.56-3.15-1.24-4.02-2.02c-0.88-0.78-1.32-1.86-1.32-3.24c0-0.68,0.17-1.31,0.49-1.9
                      s0.81-1.1,1.43-1.54s1.38-0.78,2.27-1.03c0.89-0.25,1.91-0.37,3.06-0.37c1.24,0,2.34,0.12,3.28,0.36c0.94,0.24,1.79,0.54,2.54,0.9
                      l-0.92,2.51c-0.66-0.36-1.41-0.65-2.25-0.88c-0.84-0.23-1.77-0.34-2.8-0.34c-1.27,0-2.23,0.24-2.89,0.71
                      c-0.66,0.47-0.99,0.99-0.99,1.56c0,0.27,0.05,0.52,0.16,0.75c0.11,0.23,0.31,0.44,0.6,0.64c0.29,0.2,0.68,0.41,1.17,0.61
                      c0.49,0.2,1.1,0.42,1.83,0.64c1.2,0.38,2.21,0.77,3.04,1.17c0.83,0.4,1.5,0.83,2.01,1.3c0.51,0.47,0.88,0.99,1.1,1.54
                      c0.22,0.55,0.33,1.17,0.33,1.85c0,0.79-0.18,1.5-0.55,2.13c-0.37,0.63-0.88,1.17-1.54,1.61s-1.46,0.78-2.4,1.02
                      c-0.94,0.24-1.98,0.36-3.13,0.36c-1.46,0-2.77-0.17-3.92-0.51c-1.15-0.34-2.1-0.78-2.85-1.32L292.14,99.52z"/>
                  </g>
                </g>
              </svg>
          
            <div class="divide-line"></div>
            <div id="main-tab">
              <form class="page-form" method="post">

                <div class="input-holder">
                  <input type="text"  id="fname" name="firstName" placeholder="Your name ..." @keyup=${Validator.clearWarning} required>
                  <div id="fn-req" class="required"></div>
                </div>

                <div class="input-holder">
                  <input type="email" id="userEmail" name="userEmail" placeholder="Email ..."  @keyup=${Validator.clearWarning} required>
                  <div id="em-req" class="required"></div>
                </div>

                <div class="input-holder">
                  <textarea id="userMessage" name="userMessage" placeholder="Message ..." rows="5"  @keyup=${Validator.clearWarning} required></textarea>
                  <div id="ms-req" class="required"></div>
                </div>
              </form>

              <button class="action-btn" type="submit" @click="${this.contactSubmitHandler}">Send Message</button>
              
            </div>
            <div id="status-tab"> </div>
          </div>
        </div> 
    `

    render(template, App.rootEl)
  }
}

export default new ContactView()