import {LitElement, html} from 'lit'
import {gotoRoute} from '../Router'


customElements.define('main-app-header', class AppHeader extends LitElement {
  constructor(){
    super()    
  }

  static get properties(){
    return {
      pagename: {
        type: String
      }
    }
  }

  firstUpdated(){
    super.firstUpdated()
    this.navActiveLinks()
  }

  navActiveLinks(){	
    const currentPath = window.location.pathname
    const navLinks = this.shadowRoot.querySelectorAll('.app-side-menu-items a')
    // const mobileStatus = this.isMobile()
    navLinks.forEach(navLink => {
      if(navLink.href.slice(-1) == '#') return
      if(navLink.pathname === currentPath){			
        navLink.classList.add('active')
      } 
      // else {
      //   if(currentPath === '/design' && mobileStatus == false){ 
      //     navLink.style.color = '#fff' 
      //   }
      // }
    })
  }

  // isMobile(){
  //   let viewportWidth = window.innerWidth
  //   if(viewportWidth <= 768){
  //     return true
  //   }else{
  //     return false
  //   }
  // }

  hamburgerClick(){  
    const appMenu = this.shadowRoot.getElementById('ps-drawer')
    appMenu.style.left = "0"
  }
  closeMenuClick(){  
    const appMenu = this.shadowRoot.getElementById('ps-drawer')
    appMenu.style.left = "-100%"
  }
  
  menuClick(e){
    e.preventDefault()
    const currentPath = window.location.pathname
    const pathname = e.target.closest('a').pathname
    if(currentPath == pathname){return}
    else { 
      this.closeMenuClick()
      gotoRoute(pathname)
    }
  }

  render(){    
    return html`
    <style>      
    
      * { box-sizing: border-box; margin: 0px; padding: 0px; }
      .app-header { position: relative; z-index: 99; max-height: 0; }
      #burger-div { width: 19vw; align-items: center;  display: inline-block; 
        padding: 1em; cursor: pointer; position: absolute; }
      #burger-icon { width: 55px;  top: 10px; position: absolute;  left: 10px; 
        background-color: rgba(255, 255, 255, 0.5); padding: 7px; border-radius: 500px; }
      #ps-drawer {
        position: absolute;
        z-index: 1;
        height: 100vh;
        width: 50vw;
        padding: 0 5vw;
        transition: .5s ease-out;
        left: -100%;
      }
      #nav-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        padding:  3vh 3vh;
        width: 100%;
        height: 300px;
      }
      .close-menu {
        width: 25px;
        position: absolute;
        cursor: pointer;
        right: 15px;
        top: 15px;
      }
      .p-logo { width: 120px; margin-left: -20px; }
      .app-side-menu-items { width: 80%; }
      .app-side-menu-items img { margin: auto; width: 70px; margin: 3vh 0; } 
      .app-side-menu-items a {
        display: block;
        padding: .5em;
        text-decoration: none;
        font-family: 'Arial';
        font-size: 1.2em;
        color: var(--header-inactive);
        text-align: left;
      } 
      .app-side-menu-items a:hover { color: var(--brand-light); }
      .app-side-menu-items a.active {
        color: var(--header-active);
        border-left: 1px solid var(--header-active);
      }
      .app-side-menu-items a.design { color: white; }


      @media all and (max-width: 768px){   
        #ps-drawer {
          top: 0;
          height: 100vh;
          width: 50vw;
          background-color: white;
          box-shadow: 4px 0px 5px rgba(0,0,0,0.3);
        }
        #nav-wrapper { height: 100vh;}
        .app-header {
          position: fixed;
          z-index: 90;
          max-height: 10px;
          top: 0;
          right: 0;
          left: 0;
        }
        #nav-wrapper { padding:  1vh 3vh; overflow: auto; }
        .mobile-hide { display: none; }
        #drawer-header { width: 100%; margin-top: 20px;}
      }

      @media all and (max-width: 500px){ 
        #ps-drawer { width: 80vw; } 
      }


      @media all and (min-width: 769px){  
        .desktop-hide { display: none; }
        #burger-div { display: none; }
        #title-div { margin-left: 30vw; width: 47vw; align-items: left; justify-content: left;}
        .logo-home { width: 80px;  }
        #ps-drawer {
          position: relative;
          width: 25vw;
          height: 100%;
          bottom: 0;
          left: 0;
          padding: 0;
        }
        .app-header {
          position: relative;
          z-index: 15;
          background-color: pink; 
          max-height: 10px;
        }
      }
    </style>

    <header class="app-header">
      <div id="burger-div" class="desktop-hide" @click="${this.hamburgerClick}" > 
        <svg version="1.1" id="burger-icon" alt="Menu" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="100%" viewBox="0 0 70.375 17.833" enable-background="new 0 0 70.375 17.833" xml:space="preserve">
          <circle fill="#00D1C3" cx="9.508" cy="8.884" r="8.758"/>
          <circle fill="#00D1C3" cx="35.11" cy="8.884" r="8.758"/>
          <circle fill="#00D1C3" cx="60.711" cy="8.884" r="8.758"/>
        </svg>
      </div>
      <div id="ps-drawer">
        <div id="nav-wrapper"> 
          <svg class="close-menu desktop-hide"  @click="${this.closeMenuClick}" alt="close" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              width="100%" viewBox="0 0 34.25 33.875" enable-background="new 0 0 34.25 33.875" xml:space="preserve">
            <path fill="#00D1C3" d="M22.592,16.938L32.967,6.563c0.715-0.712,1.104-1.661,1.104-2.669c0-1.01-0.39-1.959-1.104-2.673
              c-1.43-1.426-3.918-1.427-5.344,0L17.248,11.596L6.875,1.22c-1.428-1.427-3.918-1.427-5.344,0c-1.475,1.474-1.475,3.87,0,5.343
              l10.373,10.376L1.531,27.314c-0.715,0.713-1.107,1.662-1.107,2.67c0,1.012,0.393,1.959,1.107,2.674c1.426,1.426,3.916,1.426,5.344,0
              l10.373-10.377l10.375,10.377c0.715,0.711,1.662,1.104,2.674,1.104c1.008,0,1.955-0.394,2.67-1.104c1.473-1.476,1.473-3.872,0-5.344
              L22.592,16.938z"/>
          </svg>
          <nav class="app-side-menu-items">
            <svg version="1.1" class="p-logo desktop-hide" alt="peta logo" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 height="100%" viewBox="0 0 300 300" enable-background="new 0 0 300 300" xml:space="preserve">
              <rect x="-0.67" y="1" fill="#FFFFFF" width="300" height="300"/>
              <g>
                <path fill="#07A898" d="M128.1,186.38c1.13,1.17,3.16,2.43,5.63,3.61c2.45,1.16,5.21,2.19,8.07,3.05
                  c5.75,1.73,11.92,2.82,18.12,3.26c12.41,0.86,25.1-0.91,36.15-6.3c11.09-5.31,20.25-14.6,26.1-26.41
                  c5.91-11.78,8.72-25.52,9.59-39.41c0.11-1.74,0.18-3.48,0.25-5.23c-0.03-1.67-0.14-3.55-0.3-5.2c-0.31-3.4-0.8-6.85-1.55-10.21
                  c-1.49-6.74-3.86-13.29-7.16-19.37c-6.56-12.19-16.85-22.29-28.97-29.2c-12.12-6.92-26.01-10.86-40.05-11.27
                  c-7.02-0.21-14.09,0.56-20.87,2.44c-6.76,1.91-13.25,5.05-18.62,9.68c5.25-4.76,11.7-8.09,18.47-10.19
                  c6.79-2.07,13.93-3.03,21.04-3c14.23,0.05,28.47,3.69,41.08,10.47c12.6,6.76,23.56,16.93,30.75,29.51
                  c3.61,6.26,6.27,13.07,8.01,20.09c0.88,3.52,1.48,7.07,1.9,10.69c0.25,1.86,0.31,3.52,0.5,5.38c0.21,1.78,0.4,3.57,0.56,5.37
                  c1.23,14.31,0.64,29.22-4.05,43.75c-2.34,7.24-5.79,14.31-10.44,20.71c-4.63,6.41-10.47,12.09-17.08,16.66
                  c-13.29,9.2-29.13,13.88-44.91,15.2c-7.94,0.64-15.93,0.49-24.03-0.64c-4.06-0.57-8.14-1.38-12.35-2.61
                  c-4.16-1.24-8.66-2.87-13.31-5.81L128.1,186.38z"/>
                <path fill="#00D1C3" d="M191.04,160.1l-16.4-30.96l22.92-16.08c0,0-9.56-7.43-12.72-9.51c-6.32-4.17-13.04-7.81-19.98-10.9
                  c-6.88-3.05-14.09-5.46-21.44-7.2c-7.31-1.73-14.97-2.9-22.18-3.09l0.24-12.2c8.63,0.32,16.68,1.68,24.76,3.65
                  c8.03,1.99,15.88,4.68,23.45,8.12c7.49,3.42,14.7,7.41,21.56,12.02c3.42,2.3,6.75,4.76,9.97,7.39c3.26,2.7,13.67,13.46,13.67,13.46
                  L187.8,133.2L191.04,160.1z"/>
                <path fill="#07A898" d="M121.3,82.52c2.99,14.34,5.23,23.23,6.71,37.96c0.52,7.36,0.67,14.69,0.84,22.03
                  c0.1,5.4,0.16,16.2,0.16,16.2L159,141.95l12.67,29.1l19.08-11.45l5.63,10.82l-24.69,10.9l-4.42,1.95l-1.64-4.15l-10.46-26.49
                  l-32.94,17.35c0,0,1.21-34.77,1.07-49.29C123.41,106.18,122.74,97.08,121.3,82.52z"/>
                <path fill="#00D1C3" d="M90.74,49.29c6.86,15.93,10.49,33.26,12.68,50.63c2.14,17.41,2.76,35.01,2.43,52.59
                  c-0.78,35.19-5.13,70.31-12.77,105.1l-29.22-8.71c11.93-31.37,21.09-64.26,26.81-97.79c2.81-16.77,4.73-33.73,5.14-50.8
                  C96.17,83.26,95.18,66.03,90.74,49.29z"/>
              </g>
            </svg>


            <a href="/" @click="${this.menuClick}">Home</a>
            <a href="/work" @click="${this.menuClick}">Work</a>
            <a href="/contact" @click="${this.menuClick}">Contact</a>
          </nav> 
        </div>
      </div>
    </header>
  
    `
  }
})
